import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {Button, Icon, Popup, Segment} from 'semantic-ui-react'
import moment from 'moment-timezone'

import db_lib from 'xAppLib/libs/db_lib';
import {rejectOnResErr} from "../views/icosm/treatment/utils";
import obj_diff from "../xAppLib/helpers/obj_diff";
import {Duration} from "../xAppLib/UIelems/Duration";
import API_service from 'xAppLib/providers/API_service'
import obj_filter_by_key from "../xAppLib/helpers/obj_filter_by_key";
import {DataShow} from "../xAppLib/DataTable";

import Patient from 'views/patients/Patient'
import {from_melb_ui_tm, UI_DATETIME_FMT} from "../helpers/datetime";
import logger from "../xAppLib/libs/logger";
import { AnswersList } from '../xAppLib/Users/AnswersList';
import CopyField from "../xAppLib/UIelems/CopyField";
import med_model from "./med_model";
import user_model from "./user_model";
import {TreatmentStatusIcon} from "../views/icosm/treatment/components/TreatmentStatus";
// const Patient = React.lazy(_=>import('views/patients/Patient'))

function joinjsx(items, sep = <br />) {
	return items.reduce((out, item, i) => out.concat(<React.Fragment key={i}>{i > 0 && sep}{item}</React.Fragment>), []);
}
function Treatment({loc, qua, med, qnty, sz, notes, send_to, med_req_txt, doc, status}) {
	return (
		<>
			<b>{med}</b> {sz} {qnty}<br/>
			{qua && <>Quantity: {qua}<br/></>}
			{loc && <>Location: {loc}<br/></>}
			{notes && <>Notes: {notes}</>}
			{send_to && <>send to: {send_to}<br/></> ||''}
			{med_req_txt && <b><br/><li>{med_req_txt}</li></b>||''}
			{doc && status && <>Status: {status} by {doc}</>}
		</>
	);
}

function PreviousConsult({is_review, last_consult}) {
	if (!is_review) {
		return null;
	}

	const consults = last_consult || {};
	const rows = Object.values(consults);

	const treatments = Object.keys(consults).map(sid => <Treatment key={sid} {...consults[sid]} />);

	return (
		<Segment>
			{rows.length === 0 ? <><Icon name="exclamation triangle" color="orange"/> No recent consults</> : <>
				<div><b>Last Consult</b>: {rows[0].add_dt || '?'}</div>
				<div>{joinjsx(treatments, <hr className="my-2"/>)}</div>
			</>}
		</Segment>
	);
}

export function HasMoreScriptsComing({record, children}) {
	const add_tm = instcons_model.last_multirequest(record)?.add_tm;
	const [show, setShow] = React.useState(false);

	useEffect(() => {
		setShow(false);

		if (add_tm) {
			// If it has been more than 2 minutes since the nurse started a new form but still
			// no request has come through it's probably time to take the consult. Might need
			// tweaking if it genuinely takes longer than 2 minutes to get a request through.
			const stale_time = 120;

			const age = moment().diff(from_melb_ui_tm(add_tm), 's');
			if (age < stale_time) {
				setShow(true);
				const id = setTimeout(() => setShow(false), 1000 * (stale_time - age));
				return () => {
					clearTimeout(id);
				};
			}
		}
	}, [add_tm]);

	return show ? children : null;
}

const _FRDB_LOC = 'WR_instcons_cosm';
const _LIST_DET_FIELDS = [

						{
							name: 'Wait/Consult Time',
							type: 'compound',
							parts : [
								{
									name: 'add_tm',
									jpath: 'add_tm'
								},
								{
									name: 'hist',
									jpath: 'hist'
								}
							],
							template: ({add_tm, hist, row}) => {
								if (!add_tm) {
									return null;
								}

								const transitions = Object.values(hist || {})
									.filter(h => h.tm && h.to?.status)
									.sort((x, y) => x.tm - y.tm)
									.map(h => ({ status: h.to.status, tm: h.tm }));

								const first_entered_wr = from_melb_ui_tm(add_tm);
								const last_entered_wr = transitions
									.filter(h => h.status === 'instcons_await')
									.slice(-1)[0];

								const consult_taken = transitions
									// If they were put back into WR, make sure we're looking after that time
									.filter(h => h.status === 'instcons_taken' && h.tm > (last_entered_wr?.tm || 0))
									.slice(-1)[0];

								const consult_finished = transitions
									// Unlikely, but if the consult was approved/rejected then sent back to WR this
									// ensures we're only finding the last transition after that time
									.filter(h => /^instcons_(approved|rejected|cancel)$/.test(h.status) && h.tm > ((consult_taken || last_entered_wr)?.tm || 0))
									.slice(-1)[0];

								// Covers the case where the dr instant approved/rejects the consult, i.e. bypassing
								// consult taken. In this situation the consult time will be zero
								const action_taken = (consult_taken || consult_finished);

								return <>
										<div className="pl-6">
											{first_entered_wr.format(UI_DATETIME_FMT)}
										</div>
										<div style={{ fontFamily: "monospace"}}>
											<Duration icon live={!action_taken}
													  start={first_entered_wr}
													  end={action_taken?.tm}
													  iconColor={d => (
														  d.asSeconds() <= 60 && 'green'
														  || d.asMinutes() <= 5 && 'blue'
														  || d.asMinutes() <= 7 && 'yellow'
														  || d.asMinutes() <= 11 && 'orange'
														  || 'red'
													  )}
											/>
										</div>
									{consult_taken &&
										<div className="pl-6">
											{moment(consult_taken.tm).format(UI_DATETIME_FMT)}
										</div>
									}
									{action_taken && (<>
										{action_taken.status !== 'instcons_cancel' && (
											<div style={{ fontFamily: "monospace"}}>
												<Duration icon={'doctor'}
														  live={!consult_finished}
														  start={action_taken.tm}
														  end={consult_finished?.tm}
														  iconColor={d => d.asMinutes() < 1 && 'green' || 'blue'}
												/>
											</div>
										)}
										<div className="pl-6">
											{consult_finished && moment(consult_finished.tm).format(UI_DATETIME_FMT)}
										</div>
									</>)}
									<div className="flex-vertical">
										{row?.snum && <CopyField val={row.snum} altval={row.sid}/>}
										{row?.ur && <CopyField val={row.ur}/>}
									</div>
								</>;
							}
						},

						{
							name: 'Patient',
							type: 'compound',
							parts : [
								{
									name: 'First',
									jpath: 'spd_data.first_name'
								},
								{
									name: 'Last',
									jpath: 'spd_data.last_name'
								},
								{
									name: 'DOB',
									jpath: 'spd_data.dob',
									type: 'age',
								},
								{
									name: 'Email',
									jpath: 'spd_data.email',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'Sex',
									jpath: 'spd_data.sex',
								},
								{
									name: 'Mobile',
									jpath: 'spd_data.mobile',
								},
								{
									name: 'Medicare',
									jpath: 'spd_data.medicare'
								},
								{
									name: 'Conc',
									jpath: 'spd_data.conc_card'
								},

								{
									name: 'user_iding',
									jpath: 'user_iding',
								},
								{
									name: 'ihi_num',
									jpath: 'ihi_num',
								},
								{
									name: 'pts_uid',
									jpath: 'pts_uid',
								},
								{
									name: 'mc_vf',
									jpath: 'meta.medicare_verified',
								},
								{
									name: 'cn_vf',
									jpath: 'meta.concession_verified',
								},
								{name:'mdcr_first_name',jpath: 'spd_data.mdcr_first_name'},
								{name:'mdcr_last_name',jpath: 'spd_data.mdcr_last_name'},

								{ name: 'n__type', jpath: 'n__type',
									type: 'or',	jpath1: 'notes.type' },
								{ name: 'n__lvl', jpath: 'n__lvl',
									type: 'or',	jpath1: 'notes.lvl' },
								{ name: 'n__cont', jpath: 'n__cont',
									type: 'or',	jpath1: 'notes.cont' },
								{ name: 'n__desc', jpath: 'n__desc',
									type: 'or',	jpath1: 'notes.desc' },
								{ name: 'n__cre_by', jpath: 'n__cre_by',
									type: 'or',	jpath1: 'notes.cre_by' },
								{ name: 'n__cre_tm', jpath: 'n__cre_tm',
									type: 'or',	jpath1: 'notes.cre_tm' },
							],
							template : ({First,Last,DOB,Email,Sex,Mobile,Medicare, Conc, pts_view_link, mc_vf, cn_vf,mdcr_first_name,mdcr_last_name, n__type, n__lvl, n__cont, n__desc, n__cre_by, n__cre_tm, user_iding, ihi_num, pts_uid})=> <React.Fragment>
												<Link to = {`/patient/dp/${btoa(`d=${user_iding}&i=${ihi_num}&u=${pts_uid}`)}`}>
													<Icon name = {'search'} />
												</Link>
												&nbsp;
												{First} {Last}
												&nbsp;
												{n__type && n__lvl &&
													<Popup
														trigger={ <Icon
																		name={n__lvl=='info' && "info circle" || n__lvl=='warn' && "warning circle"  || n__lvl=='consult' && "doctor" || n__lvl=='alert' && "warning sign" || 'flag checkered'}
																		color={n__lvl=='info' && "green" || n__lvl=='warn' && "yellow" || n__lvl=='consult' && "blue" || n__lvl=='alert' && "red" || 'grey'}
																	/>}
														>
														<Icon
																		name={n__lvl=='info' && "info circle" || n__lvl=='warn' && "warning circle"  || n__lvl=='consult' && "doctor" || n__lvl=='alert' && "warning sign" || 'flag checkered'}
																		color={n__lvl=='info' && "green" || n__lvl=='warn' && "yellow" || n__lvl=='consult' && "blue" || n__lvl=='alert' && "red" || 'grey'}
																	/>
														<b>{n__cont}</b><br />
														{n__desc}<br />
														<i>by {n__cre_by.n} on {moment(n__cre_tm).tz("Australia/Melbourne").format('D/M/YY')}</i>
													</Popup>
												}
												<br/>
												{mdcr_first_name && <React.Fragment>({mdcr_first_name} {mdcr_last_name}) <Popup content='Real name confirmed by Medicare' trigger={ <Icon name="question circle outline"/>} /><br/></React.Fragment>}
												{Email} <br/>
												{Sex && <Icon name={Sex=='F' && 'woman' || Sex=='M' && 'man' || 'question'} color={Sex=='F' && 'pink' || Sex=='M' && 'blue' || 'green'} />}
												{DOB} <br/>
												{Mobile && <a href={'tel:'+Mobile}>{Mobile}</a>}
												<br />
												<Popup
													content={
														<Patient
															embedded
															by_par="ui"
															by_id={user_iding}
															by_uid={pts_uid}
															by_ihi={ihi_num}
															show_list="cosm_history"
															hide_act_btns
														/>
													}
													on='click'
													position="right center"
													trigger={<Button icon='user' size='tiny' label='Patient Details' />}
													style={{maxHeight:'60vh',overflow:'scroll',width:'40vw'}}
												/>
												{Medicare && <React.Fragment><br/>Medicare: {Medicare} &nbsp;
													<Icon
														name={mc_vf=='ok'&&'check'||mc_vf=='fail'&&'minus'||'question'}
														color={mc_vf=='ok'&&'green'||mc_vf=='fail'&&'red'||'yellow'}
													/></React.Fragment>}
												{Conc && <React.Fragment><br/>Conc: {Conc} &nbsp;
													<Icon
														name={cn_vf=='ok'&&'check'||cn_vf=='fail'&&'minus'||'question'}
														color={cn_vf=='ok'&&'green'||cn_vf=='fail'&&'red'||'yellow'}
													/>
													</React.Fragment>}
											</React.Fragment>
						},

						{
							name: 'Medication / Treatment',
							type: 'compound',
							// Should probably update DataShow to handle rendering an array of data, but this will do
							// for now so we can reliably render a list in InstCons_WR
							_id: 'treatment',
							parts: [
								{
									name: 'Medicine',
									jpath: 'med_db_data.name',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'Size',
									jpath: 'med_db_data.size',
									type: 'show_more',
									cut_length: 15,
								},
								{
									name: 'med_db_data',
									jpath: 'med_db_data',
								},
								{
									name: 'Quantity',
									jpath: 'cosm_det.qua',
									type: 'show_more'
								},
								{
									name: 'Location',
									jpath: 'cosm_det.loc',
									type: 'show_more'
								},
								{
									name: 'Notes',
									jpath: 'cosm_det.note',
									type: 'show_more'
								},

								{
									name: 'send_to',
									jpath: 'med_db_data.send_to',
									type: 'show_more',
									cut_length: 20,
								},
								{
									name: 'med_items',
									jpath: 'med_db_data.items',
									// type: 'show_more',
									// cut_length: 20,
								},
								{
									name: 'med_req_txt',
									jpath: 'med_db_data.req_txt',
									// type: 'show_more',
									// cut_length: 20,
								},
								{
									name: 'others',
									jpath: 'others'
								},
								{
									name: 'last_consult',
									jpath: 'last_consult'
								}
							],
							template : ({Medicine,Size,Quantity,Location,Notes, send_to, med_db_data, med_req_txt, others, is_review, last_consult, row})=> {
								const is_main_record = 'others' in row; // grouped records won't have `others`, so this is the "main" record
								return <React.Fragment>
									{is_main_record && (
										<HasMoreScriptsComing record={row}>
											<div className="my-4">
												<Icon name="hourglass half" color="teal"/> Additional scripts may be
												coming&hellip;
											</div>
										</HasMoreScriptsComing>
									)}
									{is_review && (
										<div className="my-4">
											<Icon name="magic" color="violet"/> Review Only
										</div>
									)}
									{row.products ? (<>
										<b className="inline-block mb-2">{med_db_data.name}</b>
										{Object.entries(row.products).map(([mid, product], i) => (
											<div key={mid}>
												{i > 0 && <hr className="my-2"/>}
												<b>{product.name}</b><br/>
												<span>{product.areas} <TreatmentStatusIcon status={product.status}/></span>
											</div>
										))}
									</>) : (<>
									<b>{Medicine}</b> {Size} {med_db_data?.qnty}<br/>
									{Quantity && <React.Fragment>Quantity: {Quantity}<br/></React.Fragment>}
									{Location && <React.Fragment>Location: {Location}<br/></React.Fragment>}
									</>)}
									{Notes && <React.Fragment>Notes: {Notes}</React.Fragment>}
									{send_to && <>send to: {send_to}<br/></> ||''}
									{med_req_txt && <b><br/><li>{med_req_txt}</li></b>||''}
									{(others || []).map((other) => (
										<div key={other.sid}>
											<hr className="my-2"/>
											{DataShow.show_data_field(other, _LIST_DET_FIELDS.find(x => x._id === 'treatment'))}<br />
											{DataShow.show_data_field(other, _LIST_DET_FIELDS.find(x => x._id === 'answs'))}
										</div>
									))}
									<PreviousConsult {...{last_consult, is_review}} />
								</React.Fragment>
							}
						},

						{
							name: 'Clinic',
							type: 'compound',
							parts: [
								{
									name: 'Org',
									jpath: 'org_data.name',
									type: 'show_more',
									cut_length: 35,
								},
								{
									name: 'By',
									jpath: 'auth.displayName'
								},
								{
									name: 'User',
									jpath: 'auth.email',
									type: 'show_more',
									cut_length: 30,
								},

								{
									name: 'org__address',
									jpath: 'org_data.address',
									type: 'show_more',
									cut_length: 25,
								},
								{
									name: 'org__email',
									jpath: 'org_data.email',
									type: 'or',
								},
								{
									name: 'org__contact',
									jpath: 'org_data.contact',
									type: 'or',
								},
								{
									name: 'org__phone',
									jpath: 'org_data.phone',
									type: 'or',
								},
								{
									name: 'dvc',
									jpath: 'dvc',
								},
								{
									name: 'logs',
									jpath: 'logs',
								},
								{
									name: 'uid',
									path: 'auth.uid'
								}

							],
							template : ({Org,By,User, org__address, org__email, org__phone, dvc, logs, uid})=> {
								const extra = (() => {
									try {
										const has_skyped = Object.values(logs || {}).filter(x => x.skype).pop()?.name;
										const webrtc_attempted = !!Object.values(logs || {}).find(x => x.uid === uid && x.streamId);
										const webrtc_connected = Object.values(logs || {}).filter(x => x.uid === uid && x.streamId && x.stream?.name).pop()?.stream?.name;
										const {webrtc: webrtc_supported,mediaAuthorised} = dvc || {};
										return (<>
											{dvc.online && <Icon name='eye' color="grey" title={`Online since ${new Date(dvc.online).toLocaleString()}`} />}
											{dvc.offline && <Icon name='eye slash' color="grey" title={`Offline since ${new Date(dvc.offline).toLocaleString()}`} />}
											{has_skyped && <Icon name="skype" size="small" color="teal" title={`Skyped: ${has_skyped}`} />}
											{webrtc_attempted
												? <Icon name="camera" size="small" color={webrtc_connected ? 'green' : 'yellow'} title={`In-app Video: ${webrtc_connected || 'did not connect'}`}/>
												: <Icon name="camera" size="small" color={webrtc_supported ? 'grey' : 'red'} title={`In-app Video: ${webrtc_supported ? '' : 'not'} supported`}/>
											}
											{mediaAuthorised === false && <Icon name="dont" size="small" color="red" title="Media access denied"/>}
										</>);
									} catch (e) {
										return <Icon name="exclamation triangle" title={e?.message || String(e)} color="red" />
									}
								})();

								return (<>
									<b>{Org || '-'}</b><br/>
									{By}<br/>{User}
									<br/>
									{org__address}<br/>{org__email}<br/>{org__phone}
									<div>{extra}</div>
								</>);
							}
						},

						{
							name: 'Doc',
							type: 'compound',
							parts: [
								{
									name: 'assigned',
									jpath: 'doc.nm',
								},
								{
									name: 'logs',
									jpath: 'logs',
								},
							],
							template: ({ assigned }) => {
								if (assigned) {
									return assigned;
								}
								return null;
							}
						},

						{
							name: 'Answers',
							_id: 'answs',
							type: 'compound',
							parts : [
								{
									name: 'Answers',
									jpath: 'answs',
									type: 'popup_func',
									ico: 'list alternate outline',
									compact: true,
									func: val => <AnswersList answs={val} highlightAnswer={['Yes']} />
								}
							],
							template : ({ Answers }) => <React.Fragment>{Answers || ''}</React.Fragment>
						}
					];


// const _MODEL_NAME = 'instcons_model';

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class instcons_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get LIST_DET_FIELDS() { return _LIST_DET_FIELDS; }
	// static get NEW_USER_FORM_FIELDS() { return _NEW_USER_FORM_FIELDS; }
	// static get MODEL_NAME() { return _MODEL_NAME; }

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

	static get_pending_calls() {
		return API_service.load_data('instcons/pending_calls');
	}


	static join_handler({token,peer_id,uid,name,stream_id}){
		instcons_model.set_record (token+'/peers/'+peer_id, {uid,name,stream_id})
		return ()=>{
			console.log("Leaving",peer_id);
			instcons_model.set_record (token+'/peers/'+peer_id, null)
		}

	}

	static create_session() {
		return API_service.load_data('instcons/create_session', {}/*force post*/)
	}

	static get_token(sessionId) {
		return API_service.load_data('instcons/get_token',{sessionId})
	}

	static async requireConsult(ic_wr_key) {
		return instcons_model.update_record(
			ic_wr_key,
			{reqcons: true},
			{add_hist: true}
		);
	}

	static async update_status(ic_wr_key, sid, status, msg) {
		const clear_doc = ['instcons_await', 'instcons_cancel'].includes(status);
		const is_approved = status==='instcons_approved';
		const start = Date.now();
		const updateFrdb = async () => {
			await instcons_model.update_record(
				ic_wr_key + '/',
				{
					status: status,
					...(clear_doc
							? {reqcons: null, doc: null}
							: {
								doc: {
									doc_id: app.user.claims.doc_id,
									nm: app.user.user_det.displayName,
									em: app.user.user_det.email,
									...(msg && {msg})
								}
							}
					),
				},
				{add_hist: true}
			);
		}

		const callApi = async () => {
			const payload = {
				sid,
				stat: status,
				doc: clear_doc ? null : app.user.claims.doc_id,
				msg,
				is_scr: is_approved
			};

			const res = await API_service.load_data( 'chgStat', payload);
			if (res?.res !== 'ok') {
				logger.report_error('instcons_model.update_status(chgStat)', 'error', {status, ic_wr_key, sid, res});
			}
			return res;
		};

		const updatePgdb = async () => {
			console.log('instcons_model.update_status', ic_wr_key, status, Date.now()-start);
			try {
				return await callApi();
			} catch (err) {
				logger.report_error('instcons_model.update_status(chgStat)', 'error', {status, ic_wr_key, sid, err});
				try {
					return await callApi()
				} catch (retry) {
					logger.report_error('instcons_model.update_status(chgStat)', 'error', {status, ic_wr_key, sid, retry});
					return Promise.reject(retry);
				}
			} finally {
				console.log('instcons_model.update_status', sid, status, Date.now()-start);
			}
		}

		await updateFrdb();

		return await updatePgdb();
	}

	static watch_waiting_room(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'status', where_val: 'instcons_await', ce: onError });
	}

	static watch_in_consult(onData, onError) {
		return this.watch_all_records(onData, {where_key: 'status', where_val: 'instcons_taken', ce: onError });
	}


	static watch_incomplete(onData, onError, docId) {
		const records = {
			instcons_await: {},
			instcons_taken: {},
		};
		const onCombinedData = () => onData({...records.instcons_taken, ...records.instcons_await});

		const unwatch_awaiting = instcons_model.watch_waiting_room((recs) => {
			records['instcons_await'] = recs;
			onCombinedData();
		});
		const unwatch_taken = instcons_model.watch_in_consult((recs) => {
			records['instcons_taken'] = docId
				// only show taken consults for the current doctor (otherwise show all for admin/supp)
				? obj_filter_by_key(recs, r => recs[r].doc?.doc_id === docId)
				: recs;
			onCombinedData();
		});

		return () => {
			unwatch_awaiting();
			unwatch_taken();
		};
	}

	static async recreate_session(ic_wr_key) {
		if (!ic_wr_key) {
			return Promise.reject('Missing ic_wr_key');
		}
		return instcons_model.create_session().then(rsp => (rsp.sessionId
			? instcons_model.update_record(ic_wr_key, {sessionId: rsp.sessionId})
			: Promise.reject('No session created')
		));
	}

	// Returns others that appear to be part of a multi-treatment requests given some "main" record
	// e.g. if records a and b are similar but not c, then:
	// 	- get_similar_records(a, [b,c]) => [b]
	// 	- get_similar_records(b, [a,c]) => [a]
	// 	- get_similar_records(c, [a,b]) => []
	// also, while a is "similar" (identical!) to itself, that's not helpful, so will be excluded from the list:
	// 	- get_similar_records(a, [a]) => []
	static get_similar_records(main, others = []) {
		if (!main) return [];
		if (instcons_model.is_treatment_plan(main)) return [];

		const same_or_nullish = (x = null, y = null) => x === y || x === null || y === null;

		return others.filter(other => (
			!instcons_model.is_treatment_plan(other) &&
			other !== main &&
			other.sid !== main.sid &&
			other.user_iding === main.user_iding &&
			// must have an auth.uid, if it's nullish this record is invalid
			(other.auth?.uid && other.auth.uid === main.auth?.uid) &&
			// must both be assigned to the _same_ doc, or one/both can be unassigned.
			// if both are assigned to different docs, that's weird, but don't show them as similar records
			same_or_nullish(other.doc?.doc_id, main.doc?.doc_id)
		))
	}

	static needs_consult(record) {
		const script_type = record.script_type || 'instcons';
		const others = record.others || [];

		const needs_consult = (
			script_type === 'instcons'
			|| script_type === 'doccons'
			|| record.reqcons
			|| record.is_review === false
		);

		return needs_consult || others.some(other => instcons_model.needs_consult(other));
	}

	static is_pending(record) {
		return ['instcons_await', 'instcons_taken'].includes(record.status);
	}

	static is_final(record) {
		return ['instcons_approved', 'instcons_rejected', 'instcons_cancel', 'done_doccall'].includes(record.status);
	}

	static consult_state(row) {
		const {
			status,
			sessionId,
			doc,
		} = row;

		const inWaitingRoom = status === 'instcons_await';
		const isTaken = status === 'instcons_taken';
		const invalidSession = String(sessionId).indexOf('error') === 0;
		const canOfferDrSkypeFallback = Boolean(isTaken && doc) && app.site_status.instcons_dr_skype_fallback_delay > 0;
		// if we're offering the consulting dr skype, don't show other doctors' skype
		const canOfferSkype = !canOfferDrSkypeFallback && inWaitingRoom && app.site_status.instcons_skype_fallback_delay > 0;
		const canCancel = inWaitingRoom;
		const consult_heading = doc
			? `Consultation with ${doc.nm}`
			: 'Awaiting consultation';
		const review_heading = doc
			? `Being reviewed by ${doc.nm}`
			: 'Awaiting review';

		const heading = instcons_model.needs_consult(row)
			? consult_heading
			: review_heading;

		return {
			inWaitingRoom,
			isTaken,
			invalidSession,
			canOfferDrSkypeFallback,
			canOfferSkype,
			canCancel,
			heading,
		};
	}

	static async set_multirequest(wr_key, multi = true) {
		await instcons_model.update_record(`${wr_key}/req`, {multi}, {add_hist: true});
	}

	static last_multirequest(record) {
		// Check the most recent script for this patient and see if it has been flagged
		// as a multi-request. Indicates the nurse is filling out another script for the
		// same patient and the doctor should wait before taking the consult

		const last = [record, ...record?.others || []].slice(-1)[0];

		return last?.req?.multi ? last : null;
	}

	static consult_route(record) {
		if (instcons_model.is_treatment_plan(record)) {
			return `/cosmetics/consult/${record.sid}`;
		}

		return `/instcons_doc/${record.sid}/${record.key ?? record.cosm_det?.ic_wr_key}`;
	}

	static is_legacy_script(record) {
		return !instcons_model.is_treatment_plan(record);
	}

	static is_treatment_plan(record) {
		return ['tpcons', 'tprev', 'tprpt', 'tptopup'].includes(record.script_type);
	}

	static last_skype_call(record) {
		return Object.values(record.logs || {}).filter(x => x.label==='skype_call').slice(-1)[0]
	}

	static async take_treatment_plan(record) {
		await instcons_model.update_record(
			`${record.key}/`,
			{
				status: 'instcons_taken',
				doc: {
					doc_id: app.user.claims.doc_id,
					nm: app.user.user_det.displayName,
					em: app.user.user_det.email,
				}
			},
			{add_hist: true}
		);

		return await API_service.api_promise(`cosmetics/treatment/${record.sid}/take`, {}).then(rejectOnResErr);
	}

	static async list_treatment_plans({page, pageSize, ...filters}) {
		const q = new URLSearchParams();

		q.set('offset', String((page - 1) * pageSize));
		q.set('limit', String(pageSize));

		return await API_service.api_promise(`cosmetics/treatments/list?${q}`, filters).then(rejectOnResErr);
	}

	static treatment_area_name(areaId) {
		return med_model.COSM_TREATMENT_AREA.find(x => x.key === areaId)?.text;
	}

	static async load_revisit_treatment_form_config(sid, type) {
		return await API_service
			.api_promise(`cosmetics/treatment/${sid}/${type}`)
			.then(rejectOnResErr)
			.then(r => r.data)
			.then(data => ({
				...data,
				products: data.products.map(product => ({
					...product,
					...('areas' in product && {
						areas: product.areas
							.map(area => ({
								...area,
								name: area.name ?? instcons_model.treatment_area_name(area.areaId),
							}))
							.sort((x, y) => x.name.localeCompare(y.name)),
					}),
				})),
			}));
	}

	static async load_available_products() {

		const list = await API_service
			.api_promise('cosmetics/treatments/available-products')
			.then(rejectOnResErr)
			.then(r => r.data.map(product => ({
				...product,
				...('areas' in product && {
					areas: product.areas
						.map(areaId => ({
							areaId,
							name: instcons_model.treatment_area_name(areaId),
						}))
						.sort((x, y) => x.name.localeCompare(y.name)),
				}),
			})));

		const cat_nm = 'cosm';

		return list.filter(({cid: k}) => user_model.check_access('show_medcat', {k, cat_nm, ignore_cache: true}))
	}

	static async cancel_treatment_plan(sid) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/cancel`, {}).then(rejectOnResErr);
	}

	static async release_treatment_plan(sid) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/release`, {}).then(rejectOnResErr);
	}

	static async add_treatment_plan_notes(sid, note) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/post-consult`, {note}).then(rejectOnResErr);
	}

	static async record_administered_product(sid, administered) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/inventory`, {administered}).then(rejectOnResErr);
	}

	static async amend_treatment_plan(sid, form) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/amend`, form).then(rejectOnResErr);
	}

	static async annul_direction_to_administer(sid, form) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/annul`, form).then(rejectOnResErr);
	}

	static async update_treatment_plan_photos(sid, before, after) {
		const data_diff = obj_diff(before, after);

		if (!data_diff.to || !Object.keys(data_diff).length) {
			return
		}

		const params = {
			sid,
			phts: after,
			diff: {
				...data_diff,
				part: 'phts'
			}
		}

		return await API_service.api_promise('scripts/upd_script', params).then(rejectOnResErr);
	}

	static async complete_treatment_plan(sid, form = {}) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/complete`, form).then(rejectOnResErr);
	}

	static async change_decisions(sid, form = {}) {
		return await API_service.api_promise(`cosmetics/treatment/${sid}/change-decisions`, form).then(rejectOnResErr);
	}

	static async set_vid_provider(record, vid_provider) {
		return await instcons_model.update_record(record.key, {vid_provider});
	}

	static async set_log_level(record, log_level) {
		return await instcons_model.update_record(record.key, {log_level: log_level || null});
	}

	static async add_log(ic_wr_key, label, args = {}) {
		const record = {
			...JSON.parse(JSON.stringify(args)), label, tm: Date.now(),
		};
		return await instcons_model.add_record(`${ic_wr_key}/logs`, record);
	}
}
