import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Container, Form, Grid, Input, Loader } from 'semantic-ui-react';
import { useUser } from '../../xAppLib/Hooks';
import LoginBanner from '../../xAppLib/UIelems/LoginBanner';
import logger from 'xAppLib/libs/logger'
import { Results } from "./MedSearch.Results";
import cat_model from '../../models/cat_model';
import { useNavigate } from 'react-router-dom';
import gtm from '../../xAppLib/providers/gtm';
import CatCard from '../../xAppLib/Cat/CatCard';
import { cls } from '../NUI/utils';
import Badge from '../NUI/Badge';
import { MedEnrollments } from '../UIelems/QualificationStatusIndicator';

export default React.memo(function () {
    const { user_in } = useUser();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [didYouMean, setDidYouMean] = useState(null);
    const [prompt, setPrompt] = useState();
    const navigate = useNavigate();
    const [scripts, setScripts] = useState('');
    const qualcons = app?.user?.prof?.qualcons;
    const [hint, setHint] = useState(true);
    
    const tiles = [
        {
            "active": true,
            "n": "New prescription",
            "o": "1",
            "t": "x",
            "d": "If you are a new or existing user and require a new prescription.",
            "f": {
                "lnk_txt": "Request script",
                "cst_ref": "qc_pr"
            },
            "to": "login"
        },
        {
            "active": true,
            "n": "Reorder prescription",
            "o": "1",
            "t": "x",
            "d": "You have previously received a prescription from InstantScripts and need a repeat.",
            "f": {
                "lnk_txt": " Reorder script",
                "cst_ref": "mc_std"
            },
            "to": "login"
        }
    ]

    const prompts = {
        RESTRICTED: <><h4 data-testid="restricted-med-warning" className="text-center">We cannot prescribe that medication.</h4><p className="text-center">The medication you have requested cannot be prescribed through InstantScripts. Please speak with your regular GP.</p></>,
        NO_RESULTS: (
            <>
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center" data-testid="med-search-no-results">We couldn't find that medication</h3>
                <img src="/images/med-box-sample.png" style={{ margin: '0 auto', width: 220 }} />
                <h4 className="my-4 text-is-black text-lg md:text-md text-center font-bold">
                    If you have your medication packaging, please use this to spell the name exactly as it appears on the label
                </h4>
                <Card style={{ width: '80%', margin: '72px auto 0', padding: 16 }}>
                    <Card.Content className='text-center'>
                        <Card.Header>Still can't find your medication?</Card.Header>
                        <Card.Description>
                            <p>If your medication cannot be found or you are unsure of the name, you will need to request a Telehealth Consultation for ${app.site_status.prs_mc.dc_std}
                            </p>
                        </Card.Description>
                        <Button primary style={{ marginTop: 16 }} id="medsearch-consult" onClick={(e) => {
                            gtm.log('', 'btn_click', { label: e.target?.innerHTML, id: e.target?.id, dest: '/m/pharm/DocCons/DocConsCov' })
                            navigate('/m/pharm/DocCons/DocConsCov')
                        }}>
                            Request Consultation
                        </Button>
                    </Card.Content>
                </Card>
            </>
        ),
        WRONG: (
            <>
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center">Something went wrong</h3>
                <p>Please try again, if the problem continues contact support.</p>
            </>
        )
    };

    const getSuggestions = async search => {
        setResults([]);
        setPrompt('');
        setDidYouMean(null);
        setLoading(true);
        cat_model.med_search(search)
            .then((data) => {
                if (data.res === 'ok') {
                    setHint(false)
                    if (data.restricted) {
                        setPrompt(prompts.RESTRICTED);
                    }
                    else if (data.cons) {
                        data.didYouMean ? setDidYouMean({ ...data.didYouMean, results: data.results}) : setResults(data.results)
                    }
                    else {
                        setResults(data.results);
                        setDidYouMean({ ...data.didYouMean, alt_name: data.didYouMean?.results?.[0]?.alt_name });
                        setPrompt(data.results?.length ? '' : prompts.NO_RESULTS);
                    }

                } else {
                    setPrompt(prompts.WRONG)
                }
                logger.usg_log('med_search', window.location.pathname, null, { search: search });
            })
            .catch((err) => {
                logger.usg_log('med_search', window.location.pathname, null, search, { err });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const searchDisabled = search?.trim()?.length < 3 || loading;

    return <>
    <section className={cls((user_in && scripts.length) ? 'bg-white' : 'bg-is-gray-20', 'pt-11 mb-0')}>

        <Container>
            <h1 className={cls('p-0 mb-3', (user_in && scripts.length) ? 'text-center' : 'text-left')}>{(qualcons && scripts.includes('Reorder')) && 'Reorder prescription' || (user_in && scripts.length) && 'Search for your medication' || 'Prescriptions'}</h1>
        </Container>

        {(user_in || app.dvc?.org?.type === 'pharm') && scripts.length>0 ? <div className="cat-search text-center mt-0 mx-auto flex">
            <div className={cls('w-full py-11', (qualcons && scripts.includes('Reorder')) && 'order-2 bg-is-gray-20')}>
                <Container>
                    
                    {qualcons && <div className='flex flex-col md:flex-row items-center md:w-[600px] mx-auto gap-3 mb-6'>
                        <h4 className="mb-0">Request something new:</h4>
                        <Badge small className='!px-4 text-is-gray-100 bg-is-blue-20'>Cost: ${app.site_status?.prs_mc['qc_pr']} for eligible prescriptions</Badge>
                    </div>}

                    <Form onSubmit={() => search.trim() && getSuggestions(search.trim().toLowerCase())}>
                        <div className="relative max-w-[600px] mx-auto mb-[60px] md:mb-0">
                            <Input
                                value={search}
                                icon={
                                    <div className='search-icon'>{
                                        loading
                                            ? <Loader size='tiny' active inline style={{ marginTop: "-4px" }} />
                                            : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.59842 2.80002C4.94746 2.80002 2.79843 4.94906 2.79843 7.60002C2.79843 10.251 4.94746 12.4 7.59842 12.4C10.2494 12.4 12.3984 10.251 12.3984 7.60002C12.3984 4.94906 10.2494 2.80002 7.59842 2.80002ZM0.398438 7.60002C0.398438 3.62357 3.62198 0.400024 7.59842 0.400024C11.5749 0.400024 14.7984 3.62357 14.7984 7.60002C14.7984 9.15502 14.3054 10.5949 13.4673 11.7719L19.2469 17.5515C19.7155 18.0201 19.7155 18.7799 19.2469 19.2486C18.7783 19.7172 18.0185 19.7172 17.5499 19.2486L11.7702 13.4689C10.5933 14.3071 9.15341 14.8 7.59842 14.8C3.62198 14.8 0.398438 11.5765 0.398438 7.60002Z" fill="#111827" /></svg>
                                    }</div>
                                }
                                iconPosition='left'
                                data-testid='input-search'
                                className="text-is-black-50 input-search !h-[54px]"
                                style={{ margin: '0 auto', width: '100%', borderRadius: '32px' }}
                                placeholder='Enter medication name'
                                onChange={({ nativeEvent: { target: { value } } }) => {
                                    setSearch(value)
                                    if(!value){
                                        setHint(true)
                                        setPrompt('')
                                    }
                                }}
                            />
                            <Button primary
                                className="absolute top-[60px] left-0 right-0 md:right-[5px] md:left-auto lg:bottom-1 md:top-[5px] !bg-is-blue"
                                style={{ borderRadius: '2.25rem', height: '44px', padding: '12px 24px' }}
                                type="submit"
                                data-testid="button-med-search"
                                disabled={searchDisabled}
                            >
                                Find medication
                            </Button>
                        </div>

                    </Form>

                    {hint && <div className="flex md:items-center md:justify-between bg-is-yellow py-3 px-4 lg:px-6 w-full mt-[24px] md:min-h-[98px] md:w-[600px] mx-auto rounded text-is-black gap-4">
                        <p className="text-left text-sm mb-0"><b>Hint:</b> find your medication packaging and type the name.</p>
                        <img className="h-full w-[110px]" src="https://storage.googleapis.com/instant-med-public/med-label.png" alt="med label" />
                    </div>}

                    <div className="md:max-w-[600px] mx-auto">
                        {results?.length ? (
                            <div className="py-4 md:px-4" data-testid="med-search-results">
                                <Results results={results} />
                            </div>
                        ) : didYouMean?.target ? (
                            <div className='p-4'>
                                <div className="pt-4 pb-2">
                                    <h4 className='text-is-black mb-4 text-left'>Did you mean</h4>
                                </div>
                                <Grid divided="vertically">
                                    <Grid.Row style={{ paddingVertical: 0 }} verticalAlign='middle'>
                                        <Grid.Column data-testid="did-you-mean-label" tablet={10} computer={10} mobile={11} textAlign="left">
                                            <strong>{didYouMean.target}</strong><br />
                                            {didYouMean?.alt_name && `Generic name: ${didYouMean?.alt_name}`}
                                        </Grid.Column>
                                        <Grid.Column tablet={6} computer={6} mobile={5} textAlign="right">
                                            <Button
                                                compact
                                                basic
                                                type="button"
                                                data-testid="did-you-mean-button"
                                                onClick={() => {
                                                    setResults(didYouMean.results);
                                                    setDidYouMean({ ...didYouMean, target: null });
                                                    didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                                    logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                                }}
                                            >Confirm</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        ) : didYouMean?.suggest && !results?.length ? (
                            <div className='p-4'>
                                <div className="pt-4">
                                    <h4 className='text-is-black mb-4 text-left'>Same medication, another brand</h4>
                                </div>
                                <Grid divided="vertically">
                                    <Grid.Row style={{ paddingVertical: 0 }} verticalAlign='middle'>
                                        <Grid.Column data-testid="did-you-mean-label" tablet={10} computer={10} mobile={11} textAlign="left">
                                            {didYouMean.suggest}
                                        </Grid.Column>
                                        <Grid.Column tablet={6} computer={6} mobile={5} textAlign="right">
                                            <Button
                                                compact
                                                basic
                                                type="button"
                                                onClick={() => {
                                                    setResults(didYouMean.suggestedResults);
                                                    setDidYouMean(null);
                                                    didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                                    logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                                }}
                                            >Confirm</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        ) : (
                            prompt && <div className="pt-8">{prompt}</div>
                        )}
                    </div>

                </Container>
            </div>

           {qualcons && <div className={cls(scripts.includes('New') ? "bg-is-gray-20" : "bg-white", "py-11")}>
                <MedEnrollments display="search" qualcons={qualcons} />
            </div>}

        </div> :

            <Container>
                <div className="gap-8 flex flex-col md:flex-row pt-6 pb-12">
                    {tiles.map((el, i) => {
                        const el_conf = (p) => el.f?.[p];

                        return <div onClick={() => setScripts(el.n)} className="h-full w-full md:w-[405px] ">
                            <CatCard
                                key={i}
                                el={el}
                                i={i}
                                el_conf={el_conf}
                                cat_nm="Prescriptions"
                                cat_loc="/"
                                cat_data_db={[]}
                            />
                        </div>
                    })}

                </div>
            </Container>
        }
        </section>
    </>
});