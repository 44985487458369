import React, { useState, useEffect, useMemo } from 'react';
import script_model from 'models/script_model';
import UniFormMed from 'views/med/UniFormMed';
import AccessMyHR from 'views/myhr/AccessMyHR'
import { useProfile } from '../../../../xAppLib/Hooks';
import myhr_model from '../../../../models/myhr_model';

const ENABLE_MY_HR = true

const MyHR = (props) => {
	if (!ENABLE_MY_HR)
		return  null

	const { Section, activeProfile } = props;

	if (!activeProfile)
		return null;

	return (
		<UniFormMed {...props} section="myhr" fields={script_model.MYHR_FIELDS}>
			{(values, valids, uf_this, fields) => {
				return (
					<Section>
						<Section.Header>My Health Record</Section.Header>
						<Section.Content>
							<AccessMyHR mode={AccessMyHR.SCRIPT} activeProfile={activeProfile} onUpdateAccess={(authorised)=>{
								// console.log("authorised",authorised)
								uf_this.handleInputChange(null, { name: 'myhr_grant', value: authorised });
							}}/>
						</Section.Content><br/>
					</Section>
				);
			}}
		</UniFormMed>
	);
};

export default MyHR;
