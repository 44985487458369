import React, { Component } from 'react';
import TermsAndConditions from './TermsAndConditions'
import Privacy from './Privacy'
import IlegEula from './IlegEula'
import TreatmentPlanTermsOfService from "./TreatmentPlanTermsOfService";
import ChronicConditionsModal from 'xAppLib/UIelems/ChronicConditionsModal';
import MedicalHistoryModal from 'xAppLib/UIelems/MedicalHistoryModal';
import MinorComplicationModal from 'xAppLib/UIelems/MinorComplicationModal';
import PrescriptionsUpdate from './PrescriptionsUpdate';
import HeidiConsent from './HeidiConsent';
import CmgTypeform from './CmgTypeform';
import OnePassLinked from './OnePassLinked';
import OnePassDetails from './OnePassDetails';
import MedConsultInfo from './MedConsultInfo';
import { Socials } from '../../xAppLib/UIelems/ShareToSocialsButton';
import SupportComplication from 'xAppLib/UIelems/SupportComplication'

const Content = ({ name, target="iscr", ...props }) => {
	let c = "NOT FOUND"
	switch(name){

		case 'privacy-policy':
			c = <Privacy />
			break;
		case 'treatment-plan-tos':
			c = <TreatmentPlanTermsOfService embedded />
			break;
		case 'terms-and-conditions':
			c = <TermsAndConditions type={target}/>
			break;
		case 'customer-terms-and-conditions':
			c = <TermsAndConditions type="customer"/>
			break;
		case 'platform-terms-and-conditions':
			c = <TermsAndConditions type="platform"/>
			break;
		case 'ileg-eula':
			c = <IlegEula />
			break;
		case 'chro-cond':
			c = <ChronicConditionsModal {...props} />
			break;
		case 'medical-history':
			c = <MedicalHistoryModal {...props} />
			break;
		case 'medical-history-and-chro-cond':
			c = <MedicalHistoryModal navigateToChroCond {...props} />
			break;
		case 'prescriptions-update':
			c = <PrescriptionsUpdate />
			break;
		case 'cmg-typeform':
			c = <CmgTypeform />
			break;
        case 'onepass-linked':
            c = <OnePassLinked {...props} />
            break;
		case 'onepass-details':
			c = <OnePassDetails {...props} />
			break;
		case 'minor-complication':
			c = <MinorComplicationModal {...props} />
			break;
		case 'med-consult':
			c = <MedConsultInfo {...props} />
			break;
		case 'socials':
			c = <Socials {...props} />
			break;
		case 'medical-emergency':
			// I believe this can be removed at a later stage once changes on this ticket have settled.
			// Maybe 1 month after deployment.
			c = <SupportComplication {...props} />
			break;
		case 'promo-terms-and-conditions':
			c = <TermsAndConditions type="promo"/>
			break;
		case 'heidi-consent':
			c = <HeidiConsent />
			break;
		default:
			props.txt && (c = props.txt)
	}
	return c
}
	

export default Content
