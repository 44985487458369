import React, {useEffect, useState} from "react";
import phonecall_model from "models/phonecall_model";
import { Form, Icon, Input, TextArea } from "semantic-ui-react";
import API_service from "xAppLib/providers/API_service";
import PhoneCall from "../../../xAppLib/PhoneCall/PhoneCall";
import {v4 as uuidv4} from "uuid";
import { AsyncButton } from "../../UIelems/AsyncButton";

export function AutomaticallyGeneratedNotes({sid, type = 'notes', cb, onError}) {
	const [record, setRecord] = useState(null);
    const [note, setNote] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    const disabled = app.site_status.disable_heidi && !app?.user?.prof?.xtra?.heidi_integration_override;

	useEffect(() =>
        !disabled && phonecall_model.watch_record('', d => {
            if (!d) return;
            const pcid = Object.keys(d)?.find((k) => d[k].by?.u === app?.user?.prof?.uid && d[k].transcript);
            setRecord({...d[pcid], pcid});
        }, {where_key: 'sid', where_val: sid})
	, [sid]);

	const streams = Object.values(record?.es ?? {})
        .filter(ev => 
            ev.data?.StreamSid && ['stream-stopped'].includes(ev.data.StreamEvent) ||
            ev.data?.CallStatus === 'completed'
        );

    const fetchWithDelay = async () => {
        setLoading(true);
        const res = await API_service.load_data(
			`phonecall/${record.pcid}/${type}`,
		);
        setLoading(false);

        if (res.res === "ok") {
            setNote(res.notes);
            if (inProgress) {
                setInProgress(false);
            }
        } else {

            if (res?.type === 'TwilioStreamTranscriptionInProgressException') {
                setInProgress(true);
                await new Promise(resolve => setTimeout(resolve, 3000));
                fetchWithDelay();

            } else {
                onError(res.msg);
                if (inProgress) {
                    setInProgress(false);
                }
            }

        }
    }

    useEffect(() => {
        if (streams?.length && record?.transcript?.session_id && !note && !loading) {
            fetchWithDelay();
        }
    }, [streams?.length, record?.transcript])

    if (disabled) {
        return null;
    }

	return (
        <div className="mx-4">
            <AsyncButton
                onClick={async () => {
                    const ans = await app.confirm(
                        <div>
                            <b>Are you sure you want to use the notes for this call?</b>
                            <p>
                                {note}
                            </p>
                        </div>
                    );
                    if (ans === 'yes') {
                        cb(note);
                    }
                }}
                content={inProgress ? `Processing Notes` : `Get Heidi Notes`}
                color="purple"
                disabled={!note}
                loading={loading}
            />
        </div>
	);
}

export function AutomaticallyGeneratedNotesPage() {

	const [note, setNote] = useState(null);
	const [sid, setSid] = useState(uuidv4());
    const [docNumber, setDocNumber] = useState('');
    const [patientNumber, setPatientNumber] = useState('');
    const [sessionId, setSessionId] = useState('');

	const onGetNotes = async (transcript) => {
        const data = await phonecall_model.get_consult_calls(sid);
        const pcid = !sessionId && Object.keys(data).find(pcid => data[pcid].sid);
        const url = `phonecall/${sessionId || pcid}/${transcript ? 'transcript' : 'notes'}${sessionId ? 'BySessionId' : ''}`;
        const res = await API_service.load_data(
			url,
		)

        if (res.res === "ok") {
            setNote(res.notes || res.transcript);
        } else {
            setNote("No notes found");
        }
    };

	return (
		<div className="m-2">
            <div>
                <label style={{minWidth: '200px', display: 'inline-block'}}>
                    Title&nbsp;
                    <Icon name="refresh" onClick={() => setSid(uuidv4())} />
                </label>
                <Input
                    id="sid"
                    onChange={e => setSid(e.target.value)}
                    placeholder="call ref (sid)"
                    value={sid}
                />
            </div>
            <div>
                <label style={{minWidth: '200px', display: 'inline-block'}}>Doctor Number</label>
                <Input
                    id="docNumber"
                    onChange={e => setDocNumber(e.target.value)}
                    placeholder="doc number"
                    value={docNumber}
                />
            </div>
            <div>
                <label style={{minWidth: '200px', display: 'inline-block'}}>Patient Number</label>
                <Input
                    id="patientNumber"
                    onChange={e => setPatientNumber(e.target.value)}
                    placeholder="patient number"
                    value={patientNumber}
                />
            </div>

            <PhoneCall
                sid = {sid}
                snum = {sid}
                type = {'doccons_doc_call'}
                toNum = {patientNumber}
                frNum = {docNumber}
                enableTranscription
                mode="dialler"
            />

            <Input
                fluid
                placeholder="Session ID"
                value={sessionId}
                onChange={(e) => setSessionId(e.target.value)}
            />
            <br/>
            <AsyncButton
                onClick={() => onGetNotes(false)}
                icon="notepad"
                content={`Get Heidi Notes`}
                color="green"
                basic
            />&nbsp;
            <AsyncButton
                onClick={() => onGetNotes(true)}
                icon="notepad"
                content={`Get Heidi Trascript`}
                color="green"
                basic
            />
            <br/><br/>
            
			<Form>
				<TextArea
                    placeholder="transcription output"
                    rows={10}
                    readOnly
                    value={note}
                />
            <br/>

			</Form>

		</div>
	);
}