import React, { memo } from 'react';
import { CART_LOC } from './ShopUtil';
import { useWatchRecord } from '../../xAppLib/Hooks';

export default memo(function ShopCartCount({ text }) {
	const qty = useWatchRecord(`${CART_LOC()}/qty`) ?? {};
	const count = Object.values(qty)?.filter(Boolean)?.length;

	return (
		<div className="flex items-center">
			{text}
			{count > 0 && (
				<span className={'bg-icosm-tertiary ml-1 rounded-full flex items-center justify-center text-icosm-secondary text-[12px] w-[1.5rem] h-[1.5rem] tracking-normal'}>{count}</span>
			)}
		</div>
	);
});