import React, { Suspense } from "react";

import { Route } from 'react-router-dom';
import { Container, Loader, Message } from 'semantic-ui-react'

import Header from 'xAppLib/UIelems/Header'
import SideMenu from 'xAppLib/MenuNav/SideMenu'
import Footer from 'xAppLib/UIelems/Footer'
import Confirm from 'xAppLib/UIelems/Confirm'

import Popups from 'xAppLib/UIelems/Popups'
import IdleScreen from 'views/UIelems/IdleScreen'

import MessageBar from 'xAppLib/UIelems/MessageBar'

import Routes, { ProfileRoute } from "Routes";
import Troubleshoot from 'views/UIelems/Troubleshoot'
import SupportActions from 'views/UIelems/SupportActions'
import Prescs from 'views/prescs/Prescs'
import PatientAppointments from 'views/booking/PatientAppointments'
import Logo from 'views/UIelems/Logo'
import waitFor from 'xAppLib/libs/waitFor'
import AccountOnboarding from "xAppLib/UIelems/AccountOnboarding";
import MFA from "xAppLib/UIelems/MFA";
import AuditExpirationBanner from "./views/UIelems/AuditExpirationBanner";
import Alert from "views/NUI/Alert";
import AwaitingConsults from "./views/instcons/AwaitingConsults";
import {InstConsWrNotifier} from "./views/instcons/InstConsWrNotifier";
import WatchApprovals from "views/instcons/WatchApprovals";
import WatchUnseenResults from "views/instcons/WatchUnseenResults"
import WatchUnseenResultsExpress from "views/instcons/WatchUnseenResultsExpress"

import moment from 'moment'
import { AssertiveRtdbBounce } from "./views/instcons/AssertiveRtdbBounce";
import { InProgressVideoConsults } from "./views/instcons/InProgressVideoConsults";
import SMSVerification from "./xAppLib/UIelems/SMSVerification";

import WaitTimeConsultBook from "views/public/waittime/components/consult_book";
import IcosmSideMenu, { MENU_WIDTH } from "./views/icosm/components/IcosmSideMenu";
import { cls } from "./views/NUI/utils";

// import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links';


export default class Page extends React.Component {

	constructor (props) {
		super(props)
		this.state = {
			user: null
		}

	}
	
	componentDidMount() {
		
		if (app.settings.is_ionic) {
			document.body.classList.add('ionic')
		}

		setTimeout( _=> this.setState({show_hrs_close:true}), 20*1000)

		// Force update to refresh the value of app.acl.is_blocked
		app.on(app.events.CONSENT_UPD, () => this.forceUpdate());

		app.settings.icosm && app.on(app.events.USER_UPD, () => this.setState({ user: {...app.user},}))
		
	}

	//	------------------------	------------------------	------------------------
	
	onToggleMenu = (showMenu) => {
		this.setState({showMenu})
	}
	
	

	//	------------------------	------------------------	------------------------

	renderCommonAlerts() {
		return <>
			<MessageBar hide_on={[app.runtime?.login_type=='popup'&&'user_auth_msg']} />
			<AccountOnboarding />
			<MFA bannerMode />
			{!app.acl.is_blocked && <>
				{app.acl.is_nurse && (
					<Suspense fallback={<div className="suspense-style"><Loader active inline/></div>}>
						<AwaitingConsults />
					</Suspense>
				)}
				<WatchApprovals />
				<WatchUnseenResults />
				<WatchUnseenResultsExpress />
				<InstConsWrNotifier />
				<Container className="!p-0 space-y-4 my-4 empty:my-0">
					{(app?.acl?.is_usr && !app?.settings?.icosm) ? (
						<>
							<PatientAppointments />
							<Prescs />
						</>
					) : null}
					<InProgressVideoConsults />
				</Container>
				<AssertiveRtdbBounce />
			</>}
		</>
	}

	//	------------------------	------------------------	------------------------

	render() {

		const now_open = app.settings.now_open;

		const isCosmLayout = !app.acl.is_blocked && app.settings.icosm && !app.acl.is_usr && !app.acl.is_admin && !app.acl.is_supp && !(app.acl.is_doc && !app.acl.is_NP);

		return  <React.Fragment>
					
					{!app.settings.is_bare &&
						<SideMenu showMenu={this.state.showMenu} onMenuChange={this.onToggleMenu} />
					}
					
					<div id='page-wrap'>
						
						<Header onToggleMenu={this.onToggleMenu}/>
						
						<main>

							{/*!this.state.show_hrs_close && !app.acl.is_work && app.dvc?.org?.type!='cosm' && !app.settings.is_cmg && !app.settings.icosm && !app.settings.iprep  &&
								<Message
									header={<center><b>Now {now_open ? 'Open' : 'Closed'}</b></center>}
									content={<center>
												
												{now_open ?
													moment.tz("03:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'hours')<2 && moment.tz("03:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'hours')>=0 && `Closing in ${moment.tz("03:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'hours')} hours ${moment.tz("03:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'minutes')%60} minutes.`
													: `Opening in ${moment.tz("06:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'hours')} hours ${moment.tz("06:00:00", "H:m:s", "Australia/Melbourne").add(1,'days').diff(moment(), 'minutes')%60} minutes.`}
											</center>}
									positive={now_open}
									negative={!now_open}
									onDismiss={_=>this.setState({show_hrs_close:true})}
									floating
									style={{fontSize:'.9em',boxShadow:'none',borderRadius:0, marginLeft:23, marginRight:23}}
							/>*/}

							{app.site_status?.wr_stat?.prediction?.data?.pc_98_wait<=60 && app.dvc?.org?.type!='cosm' && !app.settings.is_cmg && !app.settings.icosm && !app.settings.iprep  &&
								<center><WaitTimeConsultBook basic hideIcon color="bg-is-green-20"/></center> 
							}

							{ app.user.user_in && app.settings.ileg && !app.acl.is_leg_firm && <Alert 
								content={"Your firm account is pending verification. You will be notified when it has been reviewed."} 
								warning 
								className='text-center'
							/>}

							{!isCosmLayout && this.renderCommonAlerts()}

							<Suspense fallback={<div className="suspense-style"><Loader active inline /></div>}>
								{isCosmLayout &&
									<div className="w-full flex items-start bg-icosm-secondary divide-x divide-icosm-primary h-full">
										<div className={cls(`page-cont pb-6 w-full h-full`, !app.user.user_in? 'no-user' :'user')} style={{width: app.user.user_in ? `calc(100% - ${MENU_WIDTH})` : "100%"}}
										>
											{this.renderCommonAlerts()}
											<AuditExpirationBanner />
											<Routes />
										</div>
										<div
											style={{width: MENU_WIDTH}}
											className={`hidden ${
												this.state.user?.user_in && !app.acl.is_admin && !app.acl.is_supp && !(app.acl.is_doc && !app.acl.is_NP) && 'xl:flex'
											} sidemenu sticky top-11 right-0 flex flex-col shrink-0 bg-icosm-quaternary h-screen px-[49px] py-[44px] text-icosm-primary overflow-y-scroll`}
											>
											<IcosmSideMenu />
										</div>									
									</div> 
									|| !app.acl.is_blocked && <Routes />
									||  app.user.is_mfa_blocked && <ProfileRoute />}
							</Suspense>
							<Troubleshoot />
						</main>
						
						<SupportActions/>
						<Footer />


					</div>
					
					<Popups />
					{ app.state.loaded && app.dvc.org.type=='pharm' && !document.location.href.startsWith('http://localhost:9011/') && 
						<IdleScreen /> 
					}
					<Confirm/>
					<SMSVerification />

				</React.Fragment>

	}

}
