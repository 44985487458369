import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, Input, Loader, Segment } from 'semantic-ui-react';
import { useProfile } from 'xAppLib/Hooks';
import myhr_model from 'models/myhr_model';

const ENABLE_MY_HR = true

const modes = {
	PROFILE: 'profile',
	SCRIPT: 'script',
};

const AccessMyHR = ({ onUpdateAccess, mode = modes.PROFILE, activeProfile }) => {
	if (!ENABLE_MY_HR)
		return  null
	const [prof] = useProfile();

	const profile = activeProfile || prof
	
	const user_exists = profile.myhr?.exists
	const user_enrolled = !!profile.myhr?.user_upload_pref

	const [active, setActive] = useState(user_enrolled);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setActive(user_enrolled);
	}, [user_enrolled]);

	useEffect(() => {
		if (!user_exists) {
			(async () => {
				const res = await myhr_model.has_access(profile.uid)
				if (res?.exists)
					await app.user.reload_profile();
			})();
		}
	}, [profile?.uid]);

	useEffect(() => {
		onUpdateAccess?.(active);
	}, [active]);

	const handleAccessToggle = async () => {
		setLoading(true);
		const new_enrollment_status = !active;
		let res = false;
		if (mode === modes.PROFILE || profile.myhr?.user_upload_pref === undefined) {
			const response = await myhr_model.toggle_enrollment(new_enrollment_status, profile.uid);
			await app.user.reload_profile();
			res = response?.user_upload_pref || false;
		}
		setActive(res || new_enrollment_status);
		setLoading(false);
	};


	return (
		<>
			<Segment basic={mode == modes.SCRIPT} style={mode == modes.SCRIPT ? { padding: 0 } : {}}>
				{mode != modes.SCRIPT && (
					<p>
						<strong>My Health Record</strong>
					</p>
				)}
				{user_exists && <>
						<p>
							You can connect your {app.runtime.name} account to My Health Record and upload prescription records and other documents to
							share with other medical facilities such as your regular GP or hospital. <a href='https://www.digitalhealth.gov.au/initiatives-and-programs/my-health-record' target='_blank'>Learn more about My Health Record</a>.
						</p>
						<p>You can still opt-out to send information to My Health Record for specific requests if you wish to do so.</p>
					</> ||
					<p>
						My Health Record is <b>not available</b> for your profile, a record could not be found.  <a href='https://www.digitalhealth.gov.au/initiatives-and-programs/my-health-record' target='_blank'>Learn more about My Health Record</a> and how to register for it.
					</p>
				}
					<div className='flex flex-col gap-2'>
						<Checkbox
							toggle
							disabled={loading || !user_exists}
							checked={active}
							label='Send records to My Health Record'
							onChange={() => handleAccessToggle()}
							onClick={()=>{
								if (!user_exists) {
									app.confirm("Not available",<>My Health Record is <b>not available</b> for your profile, a record could not be found.</>,{basic:true})
									return
								}
							}}
						/>
						{loading && <Loader inverted inline />}
					</div>
				
			</Segment>
		</>
	);
};

Object.assign(AccessMyHR, modes);

export default AccessMyHR;
