export function identityToDisplayName(identity) {
	return identity.split('#')[1] ?? identity;
}

export function videoTracks(participant) {
	return [...(participant?.videoTracks.values() ?? [])];
}

export function isMediaAccessDeniedError(error) {
	return [
		'NotAllowedError',
		'NotFoundError',
		'NotReadableError',
		'OverconstrainedError',
		'TypeError',
	].includes(error.name);
}

export function isGenericError(error) {
	return error.name === 'TwilioError';
}