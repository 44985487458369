import React, { useState, useEffect } from 'react';
import { Button, Segment, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import API_service from 'xAppLib/providers/API_service'
import instcons_model from "models/instcons_model";

async function act(a, p) { 
	return await API_service.load_data( a, p ) 
}

const Signature = ({sign_fn,onClose}) => {

	const [imgData,setImgData] = useState(null)

	useEffect(_=>{
		if (!sign_fn) 
			return
		(async _=>{
			const blob = await API_service.load_binary('showSign?fn='+sign_fn)
			setImgData(URL.createObjectURL(blob))
			
		})()

	},[sign_fn])

	if (!sign_fn) 
		return null

	return <Modal open
				closeIcon
				onClose={onClose}
				>
			
				<Modal.Content>
					<Segment basic loading={!imgData}>
						<img src={imgData} style={{width:'100%'}} />
					</Segment>
					
				</Modal.Content>
			</Modal>;
}
 

const More = ({row, index }) => {
	const [sign_fn,setSign] = useState(null)

	const btns_arr = [
		!["medbuy", "exprbuy", "qualbuy", 'medcert', 'pathoreq'].includes(row.script_type) && {
			n: 'Resend Org Confirmation Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_org_confirm', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Org Email :: ${res.to}` || `ERROR Re Sending Org Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		!["medbuy", "exprbuy", "qualbuy", 'medcert', 'pathoreq'].includes(row.script_type) && {
			n: 'Resend Org Confirm Email w SendGrid', 
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_org_confirm', sid: row.sid, snd: 'SG'}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Org SendGrid Email :: ${res.to}` || `ERROR Re Sending Org SendGrid Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		row.is_script==false && {
			n: 'Resend Patient Reject Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_rej', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re-Sent Patient Email Email :: ${res.to}` || `ERROR Re-Sending Patient Email Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		!["medbuy", "exprbuy", "qualbuy"].includes(row.script_type) && {
			n: 'Resend Patient Confirmation Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_confirm', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Patient Confirmation Email :: ${res.to}` || `ERROR Re Sending Patient Confirmation Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		["medbuy", "exprbuy", "qualbuy"].includes(row.script_type) && {
			n: 'Resend MedBuy Org Email',
			a:  async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_org_medbuy', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent MedBuy Org Email :: ${res.to}` || `ERROR Re Sending MedBuy Org Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		["medbuy", "exprbuy", "qualbuy", "medclick", "exprclick", "qualclick"].includes(row.script_type) && {
			n: 'Resend MedBuy Patient Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_medbuy', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent MedBuy Patient Email :: ${res.to}` || `ERROR Re Sending MedBuy Patient Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						}, 
		},

		// row.script_type=="medbuy" && {
		// 	n: 'Send MedBuy Patient Reminder Email' ,
		// 	a: async _=> {
		// 					const res = await API_service.load_data(
		// 									'scripts/resend_eml', 
		// 									{eml: 'scr_pts_medbuy_remnd', sid: row.sid}
		// 							);
		// 					app.trigger(app.events.SHOW_MESSAGE, 
		// 						'Script List Admin', 
		// 						res.res=='ok' && `Sent MedBuy Patient Reminder Email :: ${res.to}` || `ERROR Sending MedBuy Patient Reminder Email :: ${res.to} -- ${res.err}`,
		// 						res.res=='ok' && 'positive' || 'negative')
		// 				}, 
		// },

		{
			n: 'separator',
		},

		row && row.paym && {
			n: 'Resend Patient Invoice Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_invoice', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Patient Invoice Email :: ${res.to}` || `ERROR Re Sending Patient Invoice Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		(!row.pts_uid || row.req_type=="cosm") && {
			n: 'Resend Patient Signup Invitation Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_invite', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Patient Signup Invitation Email :: ${res.to}` || `ERROR Re Sending Patient Signup Invitation Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		{
			n: 'Resend Doc Confirmation Email' ,
			a: async _=> {
						const res = await API_service.load_data(
										'scripts/resend_eml', 
										{eml: 'scr_doc_confirm', sid: row.sid}
								);
						app.trigger(app.events.SHOW_MESSAGE, 
							'Script List Admin', 
							res.res=='ok' && `Re Sent Doc Confirmation Email :: ${res.to}` || `ERROR Re Sending Doc Confirmation Email :: ${res.to} -- ${res.err}`,
							res.res=='ok' && 'positive' || 'negative')
					},
		},

		row.form_data&&row.form_data.yogp_want && {
			n: 'Resend Doctor Note Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_gp_note', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Doctor Note Email :: ${res.to}` || `ERROR Re Sending Doctor Note Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		['medbuy', 'medcons', 'exprbuy', 'exprcons', 'qualbuy', 'qualcons', 'subcons', 'doccons', 'medcert', 'pharm'].includes(row.script_type) && {
			n: 'Resend Patient Followup Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_pts_followup', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Patient Followup Email :: ${res.to}` || `ERROR Re Sending Patient Followup Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},


		(
			(["instcons", "cosm", 'tpcons'].includes(row.script_type) && ["instcons_approved", "doc_approved"].includes(row.status)) ||
			(instcons_model.is_treatment_plan(row) && ["done_doccall"].includes(row.status))
		)  &&
		row.attch?.filter(item => item.bct === "cosm-consent").length > 0 &&
		 {
			n: 'Resend Patient Consent Email',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/resend_eml', 
											{eml: 'scr_cosm_consent', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Patient Consent Email :: ${res.to}` || `ERROR Re Sending Patient Consent Email :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		{
			n: 'separator',
		},

		row.partn && {
			n: 'Resend Upd Partn Comm',
			a: async _=> {
							const res = await API_service.load_data(
											'scripts/upd_partn_comm', 
											{eml: 'upd_partn_comm', sid: row.sid}
									);
							app.trigger(app.events.SHOW_MESSAGE, 
								'Script List Admin', 
								res.res=='ok' && `Re Sent Upd Partn Comm :: ${res.to}` || `ERROR Re Sending Upd Partn Comm :: ${res.to} -- ${res.err}`,
								res.res=='ok' && 'positive' || 'negative')
						},
		},

		{
			n: 'separator',
		},

		!row.is_script && {
			n: "Allow Script",
			a: _=> act('scripts/set_is_script', {sid: row.sid, is_scr: true}),
			c: 'green',
			i: 'plus',
		},

		row.is_script && {
			n: "Decline Script",
			a: _=> act('scripts/set_is_script', {sid: row.sid, is_scr: false}),
			c: 'red',
			i: 'minus',
		},

		{
			n: 'separator',
		},

		row.sign_fn && {
			n: 'Patient signature',
			a: _=>setSign(row.sign_fn),
		},

		!row.pts_uid && {
			n: 'Patient Invite Link',
			// a: ,
			as: Link,
			to: `/r_p/${row.sid}/${row.spd_hash__dob}`,
			targ: 'actwin',
		},

	].filter(Boolean)

	if (!row)
		return null
	return <>

			{
				btns_arr.map( (b,i) => <div key={i} style={{marginBottom:'.4em'}}>
					{b.n=='separator' && <hr/>
						|| <Button
							content = { b.n }
							icon = { b.i }
							color = { b.c }
							as = { b.as }
							to = { b.to }
							// href = {b.u && b.u(row)}
							target = { b.targ }

							onClick = { b.a }

							// labelPosition='left'
							// disabled={row[this.props.f?.btns_stat_param]==b.n && !b.cust}
							// inverted={row[this.props.f?.btns_stat_param]==b.n}
							compact
							size='mini'
						/>
					}
				</div> )
			}

			<Signature sign_fn={sign_fn} onClose={_=>setSign(null)}/>
		</>
				
};



export default More
