import React, { useCallback } from "react";

import ConferenceTok from './ConferenceTok';
import ConferenceTwilio from './TwilioConference';

function VideoConference(props) {
	const {onRawStats, provider} = props;
	const statsWithProvider = useCallback((stats) => onRawStats?.({ provider, ...stats }), [onRawStats, provider]);

	if (props.provider === 'tw' && props.roomId) {
		return <ConferenceTwilio {...props} onRawStats={statsWithProvider} />;
	}

	return <ConferenceTok {...props} onRawStats={statsWithProvider} />;
}

export default VideoConference;