import React, { useState } from "react";
import { Dropdown, Form, TextArea } from "semantic-ui-react";
import { from_melb_ui_tm, UI_DATE_FMT } from "helpers/datetime";
import obj_diff from "xAppLib/helpers/obj_diff";
import { useAsync } from "xAppLib/Hooks/useAsync";
import API_service from "xAppLib/providers/API_service";
import { rejectOnResErr } from "views/icosm/treatment/utils";
import Alert from "views/NUI/Alert";
import { AsyncButton } from "views/UIelems/AsyncButton";

export function AdjustMedCertDate({req_scr_obj, onUpdate}) {
	const {sid, add_tm, meta = {}} = req_scr_obj;

	const {mc_date = null} = meta;

	const [adjustDate, setAdjustDate] = useState(mc_date);
	const [reason, setReason] = useState('');

	const can_submit = mc_date !== adjustDate && reason.trim().length > 0;

	const added = from_melb_ui_tm(add_tm);
	const yesterday = added.clone().subtract(1, "day");
	const tomorrow = added.clone().add(1, "day");

	const options = [
		{
			text: `Previous Date (${yesterday.format(UI_DATE_FMT)})`,
			value: yesterday.format('YYYY-MM-DD'),
		},
		{
			text: `Original Date (${added.format(UI_DATE_FMT)})`,
			value: null,
		},
		{
			text: `Next Date (${tomorrow.format(UI_DATE_FMT)})`,
			value: tomorrow.format('YYYY-MM-DD'),
		},
	];

	const selected = options.find((o) => o.value === adjustDate);

	const update = useAsync({
		immediate: false,
		fn: async () => {
			const initialValues = {mc_date};
			const newValues = {
				mc_date: adjustDate,
				note: reason.trim(),
			};
			const data_diff = obj_diff(initialValues, newValues);

			if (Object.keys(data_diff.to || {}).length === 0) return;

			const params = {sid, diff: {...data_diff, part: 'mc_date'}};
			await API_service.load_data('scripts/upd_script', params).then(rejectOnResErr);

			setReason('');
			onUpdate?.({meta: {...meta, ...newValues}});
		},
	});

	return (
		<Form className="flex-vertical items-start space-y-2">
			<Dropdown text={selected?.text ?? '(unset)'}>
				<Dropdown.Menu>
					{options.map((o) => (
						<Dropdown.Item key={o.value} onClick={() => setAdjustDate(o.value)}>
							{o.text}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>

			<TextArea placeholder="Reason for adjustment"
					  style={{minHeight: 30, width: '80%'}}
					  onChange={(t, d) => setReason(d.value)}
					  value={reason}
			/>

			<Alert error={Boolean(update.error)} visible={Boolean(update.error)}>
				{update.error?.error || String(update.error)}
			</Alert>

			<AsyncButton onClick={update.fn} disabled={!can_submit} color="blue">
				Update
			</AsyncButton>
		</Form>
	);
}
