import React, { Component } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react'

import user_model from 'models/user_model'
import orgs_model from 'models/orgs_model'

import SelectTree from '../TreeStruct/SelectTree'

class SelectOrg extends Component {

	constructor (props) {
		super(props)

		this.state = {
			tree_data: null,
		};

		this.load_tree();
	}

	//	------------------------	------------------------	------------------------

	async load_tree () {

		if (!user_model.check_access('view_all_orgs') && !user_model.check_access('view_my_orgs'))
			return

		const orgs_tree = await orgs_model.get_orgs_tree()

		this.setState({ tree_data: orgs_tree })
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	render () {

		const { 
					multiple, 
					leafOnly, 
					oid, 
					onum, 
					onSelect, 
					placeholder, 
					setBtn,
					show_loading,
					required,
				} = this.props

		const { curr_oid, tree_data } = this.state

		if (!tree_data)			return <>Loading</>

		return <>
			
				{ user_model.check_access('view_all_orgs') && 
				<SelectTree

						get_tree_data = { async _=> await orgs_model.get_orgs_tree() }
						tree_data = { tree_data }
						// tree_data = { (await orgs_model.get_orgs_tree()) }
						// tree_data = { app.app_data.ws_data?.orgs?.tree.tree }

						the_model = { orgs_model }

						obj_key_nm = {onum!=null&&'onum'||'oid'}
						curr_objs_keys = {onum??oid}

						sel_btn_col = { sel_objs => {
														const hasPharm = sel_objs&&sel_objs.filter(o=>o&&o.type=="pharm").length
														const hasCosm = sel_objs&&sel_objs.filter(o=>o&&o.type=="cosm").length
														return hasCosm && hasPharm && "purple" 
																	|| hasCosm && "pink" 
																	|| hasPharm && 'blue' 
																	|| sel_objs&&sel_objs.filter(o=>o&&o.type=="doc").length && "teal" 
																	|| sel_objs&&sel_objs.filter(o=>o&&o.type=="supp").length && "olive" 
																	|| sel_objs&&sel_objs.length && 'brown' 
																	|| undefined
													} }
						sel_btn_ico = { sel_objs => {
														const hasPharm = sel_objs&&sel_objs.filter(o=>o&&o.type=="pharm").length
														const hasCosm = sel_objs&&sel_objs.filter(o=>o&&o.type=="cosm").length
														return hasPharm && hasCosm && "boxes" 
																|| hasPharm && "medkit" 
																|| hasCosm && "syringe" 
																|| sel_objs&&sel_objs.filter(o=>o&&o.type=="doc").length && "doctor" 
																|| sel_objs&&sel_objs.filter(o=>o&&o.type=="supp").length && "shipping" 
																|| sel_objs&&sel_objs.length && "question" 
																|| "minus"
													} }

						multiple = {multiple}
						leafOnly = {leafOnly}
						onSelect = { (org)=> {
							onSelect(org)
						}}
						placeholder = {placeholder || 'Select Organisation'}
						setBtn = {setBtn}

						show_loading = {show_loading}
						required = {required}
				/>

			|| user_model.check_access('view_my_orgs') && 
				<Dropdown 
					placeholder = {placeholder || 'Select Organisation'}
					selection
					// multiple = {multiple}
					search
					// value={this.state.org_sel_value}
					value={onum || oid}
					multiple = {multiple}
					onChange={  (e, d) => {
						console.log("onChange()",e,d)
						// console.log("=>",d.value.map(value=>tree_data.find(o => o.oid == value)))
						onSelect(multiple ? d.value.map(value=>tree_data.find(o => o.oid == value)): tree_data.find(o => o.oid == d.value))
					} }
					// onChange={ async (e, d )=> {this.set_DD(d.value)}}

					loading = {show_loading}
					required = {required}
					options={ tree_data?.map(s => ({key: 'md_'+s.oid, value: s.oid, text: s.path})) }
				/>
			}</>

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

}

export default SelectOrg;
