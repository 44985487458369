import React, { useState } from "react";
import UniForm from "xAppLib/UniForm/UniForm";
import { Form, Popup, Icon, Button, Label, Segment } from "semantic-ui-react";
import Photos from "xAppLib/Photos/Photos";
import script_model from "models/script_model";
import UniFormMed from "views/med/UniFormMed";
import moment from 'moment-timezone';
import { obj_map } from "../../../../xAppLib/helpers/obj_map";

const RENDER_AS_BUTTON = true

function boldify(text) {
	return text.split('**').map((t, i) => i % 2
		? <strong key={i}>{t}</strong>
		: <React.Fragment key={i}>{t}</React.Fragment>
	);
}

function determine_question_visibility(questions, answers) {
	const visible = {};
	function is_looping(key, seen = []) {
		if (seen.includes(key)) return false;

		const qs = questions[key]?.config?.cond?.qs || [];
		for (const q of qs) {
			if (is_looping(q.q, seen.concat(key))) return true;
		}

		return false;
	}

	function is_visible(key) {
		if (key in visible) return visible[key];

		const question = questions[key];
		const qs = question.config?.cond?.qs || [];
		const sticky = question.config?.cond?.sticky;

		if (!qs.length) return true;

		for (const q of qs) {
			// checks for misconfiguration, if anything wrong, default to showing the question
			// no parent q&a, either not conditional visibility or not configured correctly
			if (!(q.q && q.a?.length > 0)) return true;
			// dependent question or answer doesn't exist?
			if (!(q.a.some(a => questions[q.q]?.a?.[a]))) return true;

			// once a "sticky" question is visible, it stays visible regardless of the dependent question's answer
			if (typeof answers[key] !== 'undefined' && sticky) return true;

			// our dependant question isn't visible, so neither are we
			if (!is_visible(q.q)) return false

			// finally, display if dependent question's answer is what we're expecting
			if (q.a.includes(answers[q.q])) return true;
		}

		return false;
	}

	for (const k in questions) {
		// if the questions have been configured in a loop, just show them all, somebody messed up
		visible[k] = is_looping(k) || is_visible(k);
	}

	return visible;
}

const MedicalConsultation = (props) => {
	const { med_data, script_type, req_type, store, qs, qs_sorted, onUpdate, Section, formData } = props

	const [visibility, setVisibility] = useState(() => determine_question_visibility(qs, formData));
	const fields = React.useMemo(() => ( script_type!='pthl-needlestick' && script_model.prep_radio_form_fields(qs_sorted) || [] ),
		[med_data, script_type, req_type, qs_sorted]
	);
	const applicableQs = React.useMemo(() => fields.filter(f => visibility[f.name] !== false), [fields, visibility]);

	if (qs_sorted.length==0)
		return null

	return (
		<UniFormMed
			{...props}
			section="consultation"
			fields={applicableQs}
			onChange={(target, value, values) => {
				const res = props.onChange(target, value, values);

				const vis = determine_question_visibility(qs, values);
				setVisibility(vis);

				// Setting to undefined takes the answer out of the form
				// Setting as null ensures a sticky answer is kept in the form even if it would normally be hidden.
				const answers_to_keep = obj_map(vis, (visible, k) => visible ? (typeof values[k] !== 'undefined' ? values[k] : null) : undefined);

				return {
					...res,
					...answers_to_keep
				};
			}}
		>
			{(values, valids, uf_this, fields) => {
                const { patho_tests } = store
				return <Section>
                        <Section.Header>Digital medical consultation</Section.Header>
						<Section.Content>
						{ applicableQs.map( (f,qnum) => {
								const k = f.name
								const q = qs[k];
								const config = qs[k].config || {}
								return <Form.Group
										grouped
										required
										key={"form_radio_q_"+k}
										style={{marginBottom:'40px !important'}}
										className={q.c}
										data-testid="question-group"
									>
									<label>
										{/*{q.num && <span>{q.num}. </span>}*/}
										<span>{qnum+1}. </span>
										<span data-testid='question-text' className={(valids && !valids[k])?'text error':undefined}>{q.txt}</span>
										
										{ q.desc &&
											<>&nbsp; &nbsp; &nbsp;
											<Popup
												trigger={<Icon name='question circle outline' />}
												content={q.desc}
											/></>
										}
										
									</label>
									{q.details && <pre>{boldify(q.details)}</pre>}
									<div className={RENDER_AS_BUTTON?"q-buttons":"q-radios"}>
									{ q.a &&
										Object.keys(q.a)
										.map( (ak, ai) => RENDER_AS_BUTTON ? 
										<div
											key={"form_radio_q_"+k+"_a_"+ak}
											>
												<Button 
														type="button"
														basic={values[k] != ak}
														color={values[k] == ak?'blue':(valids && !valids[k] ? 'red' : 'grey')}
														size="small"
														primary={values[k] == ak}
														onClick={()=>uf_this.handleInputChange({target:{name:k, value:ak}})}
													>
																{/*q.a[ak].num &&
																	<span>{q.a[ak].num}. &nbsp; </span>*/}

																{q.a[ak].txt}
																
													</Button>
													{ q.a[ak].desc &&
														<Popup
																		trigger={<Label as='a' basic color='teal' pointing="left">
																				<Icon name='question circle' style={{marginRight:0}} size="large"/>
																				</Label>}
																		content={q.a[ak].desc}
																	/>
													}
		
													
													</div>
													: <Form.Radio

													label={{ children:
															<React.Fragment>
																&nbsp;
																{q.a[ak].num &&
																	<span>{q.a[ak].num}. &nbsp; </span>}

																{q.a[ak].txt}

																&nbsp; &nbsp; &nbsp;
																{ q.a[ak].desc &&
																	<Popup
																		trigger={<Icon name='question circle outline' />}
																		content={q.a[ak].desc}
																	/>
																}
															</React.Fragment> }}

													name={k}
													value={ak}
													checked={values[k] == ak}
													onChange={()=>uf_this.handleInputChange({target:{name:k, value:ak}})}
													error = {valids && !valids[k]}

													key={"form_radio_q_"+k+"_a_"+ak}
												/>
											)
									}
									</div>
									
									{config.file && (!config.file_answer || config.file_answer==values[k]) && <React.Fragment>
										<h4 className="mb-1 mt-2">{config.file_message}</h4>
										<Photos
											show_disclaim
											target="medreq"
											hideComment={true}
											tag={k}
											tag_label={q.txt}
											inline
											size='calc(100% / 3 - 2em)'
											data = {values['phts'] && values['phts'].filter(p=>p.tag==k) || []}
											onChange = { ps => {
												const other_photos = (values['phts'] || []).filter(p=>p.tag!=k);
												const value = other_photos.concat(ps);
												return uf_this.handleInputChange(null, {name:'phts', value});
											} }
										/>
									
									</React.Fragment>}

									<ExtraInfoField
										{...{config, values, valids }}
										questionId={k}
										onChange={uf_this.handleInputChange}
									/>


								</Form.Group>
							}
						) }
						</Section.Content>
                    </Section>;
			}}
		</UniFormMed>
	);
};

function ExtraInfoField({values, questionId, valids, config, onChange}) {
	if (!config.text || (config.text_answer && config.text_answer !== values[questionId])) {
		return null;
	}

	// Validation function is on the base question, so determine error from there. See script_model.prep_radio_form_fields
	const error = valids && !valids[questionId];
	const inputName = `${questionId}_text`;

	return (
		<React.Fragment>
			<Form.Input
				label={config.text_prompt}
				name={inputName}
				value={values[inputName] || ''}
				onChange={(e, {name, value}) => onChange({target: {name, value}})}
				error={error}
			/>
			{config?.text_desc && <p className="text-sm">{config.text_desc}</p>}
		</React.Fragment>
	);
}

export default MedicalConsultation;
