import React from 'react';

const HeidiConsent = () => {

  return (
    <div className="terms">
      <h1>
        <b>Medical AI scribe</b>
      </h1>
      <p>At InstantScripts, we utilise an automated note taking tool provided by a third-party service provider called Heidi to accurately and efficiently capture the details and outcomes of your consultations.</p>
      <p>When the Heidi system is activated, an audio recording will be made of your conversation with your doctor and sent to Heidi. Heidi will use this recording to produce an automated summary of the consultation. Heidi won't share your information with anyone else and does not retain a copy of the audio recording, your information will remain in Australia throughout the process.</p>
      <p>Heidi helps to ensures that your doctor can focus more on your conversation and less on manual note taking, enhancing the quality of care you receive.</p>
      <p>Your consent is crucial for us to use this technology. If you do not provide consent, InstantScripts won’t use the Heidi system, and your doctor will take notes manually instead.</p>
      <p>
        Please understand that your information will be handled with the utmost care, and Heidi’s use is aimed solely at improving your healthcare experience. Heidi will handle your personal information in accordance with their privacy policy at 
        <a className="text-blue-800 ml-1" target="_blank" href="https://www.heidihealth.com/au/legal/privacy-policy">
            https://www.heidihealth.com/au/legal/privacy-policy
        </a>
        , which contains information about how to request access to or correction of your personal information, how to make a privacy complaint and how Heidi will handle such complaints.
      </p>
    </div>
  );
};

export default HeidiConsent;
