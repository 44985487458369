import { INIT_MAP } from "./components";

export default function reducer({ state, action, onDataChange }) {
    const updateState = { main: [...state.main], aside: [...state.aside] };
    let updated;

    switch (action.type) {
        case "REMOVE":
            updated = state[action.target].filter(
                (item) => action.order !== item.order
            ).map((item, index) => ({ ...item, order: index }));

            updateState[action.target] = [...updated];
            onDataChange(updateState);
            
            return { ...state, [action.target]: updated };

        case "ADD":
            updateState[action.target] = [
                ...state[action.target],
                {
                    component: action.component,
                    ...INIT_MAP[action.component],
                    order: state[action.target].length,
                },
            ];
            onDataChange(updateState);

            return { ...updateState };

        case "UPDATE":
            updated = state[action.target].map((item) =>
                item.order === action.order
                    ? { ...item, ...action.update }
                    : { ...item }
            );

            updateState[action.target] = [...updated];
            onDataChange(updateState);

            return { ...updateState };

        case "UP":
            updated = updateState[action.target].map(item => {
                if (action.order === item.order) return {
                    ...item,
                    order: item.order - 1
                }
                if (action.order - 1 === item.order) return {
                    ...item,
                    order: item.order + 1
                }
                return item;
            });

            updateState[action.target] = [...updated];

            onDataChange(updateState);

            return { ...updateState };

        case "DOWN":
            updated = updateState[action.target].map(item => {
                if (action.order === item.order) return {
                    ...item,
                    order: item.order + 1
                }
                if (action.order + 1 === item.order) return {
                    ...item,
                    order: item.order - 1
                }
                return item;
            });

            updateState[action.target] = [...updated];
            onDataChange(updateState);

            return { ...updateState };

        default:
            throw new Error();
    }
}
