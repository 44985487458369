/**
 * Create a lookup object from a list of items.
 * Duplicate keys are silently ignored, and the most recent value is used.
 * @param {T[]} list
 * @param {(item: T) => string} keyFn
 * @param {(item: T) => U} valFn
 * @return {Record<string, U>}
 * @template T
 * @template U
 */
export function keyBy(list, keyFn, valFn = x => x) {
	const lookup = {};

	for (const item of list) {
		const key = keyFn(item);
		lookup[key] = valFn(item);
	}

	return lookup;
}