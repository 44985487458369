import React, { useState, useEffect } from "react";
import {
	Button,
	Container,
	Loader,
	Accordion,
} from "semantic-ui-react";
import { Link, useSearchParams } from "react-router-dom";
import UserReferral from "views/UIelems/UserReferral";
import { useProfile, useUser } from "xAppLib/Hooks";
import gtm from "../../xAppLib/providers/gtm";
import { cls } from 'views/NUI/utils';
import moment from "moment";
import SIcon from "../NUI/StreamlineIcons/SIcon";
import Slider from "../NUI/Slider";
import LearnMore from "xAppLib/AHPRA/LearnMore";
import user_model from "../../models/user_model";
import FAQ from "../../xAppLib/UIelems/FAQ";
import MainTiles from "../../xAppLib/UIelems/MainTiles";
import ConditionsAndServicesTiles from "../med/ConditionsAndServicesTiles";
import FeatureTiles from "../../xAppLib/UIelems/FeatureTiles";

const img_path = "https://storage.googleapis.com/instant-med-public/Home";

const Review = ({wide}) => {
	return <div className={cls(wide ? 'wide':'compact', 'review-actions')}>
		<div className={cls(!wide && "order-last")}>
			{/*<a onClick={(e) => { e.preventDefault(); gtm.log('', 'outbound', {link: e.currentTarget.href}); window.open(e.currentTarget.href, '_blank').focus(); }} href='https://www.productreview.com.au/listings/instantscripts' target='_blank'>*/}
				<img src="https://api.productreview.com.au/api/services/rating-badge/v2/au/e7b8b688-a5ac-4c9c-9607-d21714b7c6f9/from-internal-entry-id?resolution=hd&amp;theme=light&amp;width=160&amp;hideLabel=true" alt="InstantScripts" width="200" />
			{/*</a>*/}
		</div>
		<div>
			<h2 className={cls(!wide && 'lg:w-2/3 xl:w-3/4')}>Discover why thousands of Australians use InstantScripts</h2>
			<p className={cls(!wide && 'w-1/2')}>People from all over Australia are loving our service.</p>
			{/*<a onClick={(e) => { e.preventDefault(); gtm.log('', 'outbound', {link: e.target.href}); window.open(e.target.href, '_blank').focus(); }} href='https://www.productreview.com.au/listings/instantscripts' target='_blank' className='ui button'>Leave a review</a>*/}
		</div>
		
		
	</div>
}
 
const CTA = ({ user_in }) => {
    const show_hist = user_in && app.user.prof?.pts_scrs > 0;

    return (
        <>
            <MainTiles
                show_hist={show_hist}
                learnmore
				pr_btn
                title={
                    <>
                        {!user_in && (
                            <h2 className="section-title font-bold md:font-normal text-center text-is-blue mb-6">
                                How can we help you today?
                            </h2>
                        )}
                    </>
                }
				pt="sm:pt-[60px]"
            />

            <div className="extra-actions mx-4 md:hidden">
                <Button
                    inverted
                    color="blue"
                    className="contact"
                    fluid
                    onClick={(_) => {
                        app.trigger(app.events.SHOW_POPUP, {
                            tp: "pu",
                            pt: "contact",
                            txt: "Contact Us",
                            u: "contact-us",
                            pn: "contact-us",
                            target: "iscr",
                        });
                    }}
                >
                    Contact Us
                </Button>
            </div>
        </>
    );
};

const Hero = ({user_in, about}) => {

    const [prof] = useProfile()
	let bnr_day = moment().isBetween(moment("06:00", "HH:mm"), moment("18:00", "HH:mm"), null, '[]')
	const days_old = moment().diff(moment(app.user?.prof?.cre_tm),'days')
	const newUser = days_old < 3

	const user_header = user_in && !app.state.in_sgup_st2;

	const qualcons = prof?.qualcons?.filter(q=>q.qualification_status === 'current' && q.enrolled_med !=='*') || [];

	const show_history = app.acl.has_history && qualcons.length ==0


    return <div id="home-section-hero" className="home-section home-section-hero -mt-2">
				<div className={cls("section-user relative bg-cover", user_header ? "user bg-is-gray-20 min-h-[400px]" : "bg-is-blue-100 lg:bg-[#080808] no-user flex flex-col flex-col-reverse lg:block h-full")}>
					
					<div className={`hero-img bg-no-repeat w-full relative
						${user_header ? 'sm:h-full sm:absolute h-[345px] bg-cover xl:bg-[length:auto_100%] bg-hero-return-sm sm:bg-hero-return-lg bg-[50%_25%] sm:bg-[70%_50%] xl:bg-right' : `lg:absolute h-[220px] sm:h-[320px] lg:h-full bg-[#080808] lg:bg-transparent bg-cover ${bnr_day ? 'bg-hero-day-sm md:bg-hero-day-lg bg-[50%_35%] lg:bg-center xl:bg-[50%_20%]' : 'bg-hero-sm lg:bg-hero-lg 2xl:bg-contain bg-top'}`}`}>
					</div>

			    	<Container className="h-full fluid-fixed !pt-0">
                        <div className={cls(user_header ? "sm:grid-cols-10" : "lg:grid-cols-10", "h-full grid home-section hero-container py-6 md:py-0")}>
							<div className={cls("sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-6 2xl:col-span-8 sm:z-10 text-center sm:text-left")}>
								{user_header && <div className="py-4 sm:pt-20 sm:pb-32">
									<h1 className="section-title text-is-blue">Welcome{newUser ? "!" : " back!"}<br /> How can we help you?</h1>
									<p className="section-subtitle text-gray-600 text-md">{show_history && <>Your history is stored securely to make reordering<br className='lg' /> your prescription easy and convenient.</> || <>Online prescriptions, telehealth consultations,<br className='lg' /> blood test requests, and medical certificates</>}</p>
                                    {show_history && qualcons.length ==0 && <Link to="/history">
                                        <div>
                                            <span className="ui button primary"><strong>Reorder a Script</strong></span>
                                        </div>
                                    </Link>}
                                </div> ||
								<div className="hero-wrap lg:bg-is-blue md:py-8 lg:py-10 lg:px-8 lg:max-w-[608px] lg:my-[70px] rounded-[12px]">
									<h1 className="!text-white no-user text-center md:text-left w-full text-[2rem] md:text-[2.5rem] leading-10 md:leading-[56px] mb-6">24/7 healthcare from anywhere in Australia</h1>
									<ul className="list-disc list-inside text-left md:text-[1.25rem] md:leading-7 mx-auto md:mx-0 w-fit">
										<li>Trusted by over 2 million Australians</li>
										<li className="hidden lg:list-item">Doctors available anytime day or night</li><li className="list-item lg:hidden">Speak to a doctor anytime</li>
										<li className="hidden lg:list-item">Request a consult, script or medical certificate</li><li className="list-item lg:hidden">Request a medical certificate or script</li>
									</ul>
								</div>}
								<span className="home-anchor" id="try-instantscripts-now"></span>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
};

const HIW = () => {

	const tile_data = [
		{
			num: 1,
			desc: 'Complete online prescription request',
			icons: ['monitor-cross', 'smartphone-cross-hand']
		},
		{
			num: 2,
			desc: 'Select collection method – pick up or home delivery',
			icons: ['steth-heart', 'medical-check-cross']
		},
		{
			num: 3,
			desc: 'A doctor will review your request online or call you',
			icons: ['pill-bottle', 'chart-pills']
		},
		{
			num: 4,
			desc: 'If eligible an eScript will be sent to your phone or medication delivered',
			icons: ['retail-counter', 'delivery-pill']
		},
	]
	return <div id="home-section-hiw" className="home-section home-section-hiw pt-6 pb-0 sm:py-8 bg-is-gray">
		<div className="ui container fluid-fixed">
			<div className="pb-8"><h2 className="!mb-4 !md:mb-6 text-is-blue">How online prescriptions work</h2></div>
		  	<div className="section-content ui grid">
		    <div className="tiles ui row">
		  	{tile_data.map((el, i) =>
			  <div className="content-col column sixteen wide mobile eight wide tablet four wide computer" key={i}>
				<div className="mx-auto bg-white h-[52px] w-[52px] rounded-full flex items-center justify-center border-2 border-is-blue absolute top-[-25px] left-0 right-0"><h2 className="center text-is-blue">{el.num}</h2></div>
			    <div className="inner flex flex-col items-center justify-between mx-auto mb-10 sm:mb-0">
			      <p className="mb-2">{el.desc}</p>
				  <div className="flex items-center justify-center text-is-blue">
					<SIcon name={el.icons[0]} size="lg"/>
					<SIcon name={el.icons[1]} size="lg"/>
				  </div>
			    </div>
			  </div>
			)}
		  </div>
		</div>
	  </div>
  	</div>
}

const ImageTextBanner = () => {
	return <div className="home-section image-text-banner bg-transparent sm:bg-is-gray py-6 sm:py-8 bg-no-repeat bg-[top_0] sm:bg-[35%_40%] md:bg-center xl:bg-[50%_30%] bg-[length:auto_280px] sm:bg-cover" style={{backgroundImage: `url(${img_path}/woman-on-phone-banner.jpg)`}}>
		<div className="ui container">
		  <div className="grid sm:grid-cols-2 sm:gap-4">
		  <div className="col-span-1"></div>
		  <div className="col-span-1 mt-[280px] sm:mt-0">
		  	<div className="section-header ui center aligned header-wrap mx-auto" style={{padding:0}}>
				<h2 className="section-title text-center sm:text-left">Need to speak to a doctor?<br/>Request a telehealth consultation</h2>
			</div>
			<div className="p-2 text-left mb-4">
				<p>Request a Telehealth appointment with InstantScripts and you can be speaking to an Australian-registered doctor from the comfort of your home.</p>
				<ul className="pl-4 list-disc">
					<li>Experienced and professional doctors</li>
					<li>Typical call back within an hour</li>
					<li>No cancellation fees</li>
					<li>High customer satisfaction</li>
				</ul>
			</div>
			<Button className="mt-auto md:max-w-fit" color="blue" fluid as={Link} to="/cat/DocCons">Request a Consultation</Button>
		  </div>
		</div>
	  </div>
  	</div>

}

const Partners = () => {

	const partners =
		[
			{
				name: 'Wesfarmers Health',
				img: img_path+'/wesfarmers.svg',
				link: 'https://www.wesfarmers.com.au/our-businesses/health'
			},
			{
				name: 'Priceline',
				img: img_path+'/priceline_pharmacy.svg',
				link: 'https://www.priceline.com.au/'
			},
			{
				name: 'NSW Health',
				img: img_path+'/nsw_health.svg',
				link: 'https://www.health.nsw.gov.au/'
			},
			{
				name: 'Australian Patients Association',
				img: img_path+'/australian_patients_association.svg',
				link: 'https://www.patients.org.au/'
			},
			{
				name: 'My Mirror',
				img: img_path+'/my_mirror.svg',
				link: 'https://www.mymirror.com.au/'
			},
			{
				name: 'Health Engine',
				img: img_path+'/Health_Engine_logo.svg',
				link: 'https://healthengine.com.au/'
			},
			{
				name: 'RACGP',
				img: img_path+'/racgp-cpd-provider.svg',
				link: 'https://www.racgp.org.au/'
			},
			{
				name: 'OnePass',
				img: img_path+'/one_pass.svg',
				link: 'https://onepass.com.au/'
			}
		]

	return  <div className="partner bg-is-gray py-4 sm:py-6 px-2 sm:px-4 flex flex-wrap xl:flex-nowrap items-center justify-center">
				<p className="text-is-black-50 sm:mr-10 mb-2 xl:mb-0 w-full sm:min-w-[172px] sm:w-fit text-center sm:text-left">We’re partnered with:</p>
				<div className="hidden sm:flex items-center justify-center gap-8 flex-wrap xl:flex-nowrap">{partners.map((el, i) => <a href={el.link} target="_blank" key={i}><img className="h-[35px]" src={el.img} /></a> )}</div>
				<div className="block sm:hidden"><Slider auto data={partners} clsn="grid-col-1 h-[29.5px]" cont="grid grid-cols-3 gap-5 max-w-[295.38px] overflow-hidden h-[30px]" img_cls="h-full"/></div>
			</div>
}

const Testimonials = () => {
	const total = 4.7;
	const stars_empty = 5 - Math.ceil(total);
	const perc = Math.round((total - parseInt(total)) * 100);
	const img_path = "https://storage.googleapis.com/instant-med-public/Home";
	const reviews = [
		{
			name: "Jasmine",
			text: "So easy and straight forward especially when you are stuck at home sick and know what you need but can’t get to a doctor. Hassle free and reasonably priced."
		},
		{
			name: "Steve",
			text: "I use InstantScripts as I find it difficult to get to my doctor on a regular basis. The service is quick and the reminders are very handy. InstantScripts has given me peace of mind when unable to get to see my doctor. Highly recommended."
		},
		{
			name: "Happy",
			text: "Very helpful around topics that I know are common and natural but embarrassing to sit and discuss with someone."
		},
	]

	return <div className="py-6 sm:pt-8 sm:pb-12">
			<svg className="w-0 h-0" viewBox="0 0 24 24"><defs>
				<mask id="perc">
					<rect x="0" y="0" className="h-full w-full fill-white" />
					<rect x={String(perc) + '%'} y="0" className="h-full w-full fill-[#3d3d3d]" />
				</mask>
				<symbol className="h-fit" xmlns="http://www.w3.org/2000/svg" id="star">
					<path d="M12.5212 0.447798L15.8362 7.07613L22.2129 7.71446C22.552 7.74763 22.7789 7.92463 22.8937 8.24538C23.0085 8.56613 22.9455 8.84696 22.7045 9.0878L17.4495 14.3411L19.3979 21.4795C19.4889 21.8159 19.3997 22.0945 19.1302 22.3155C18.8607 22.5365 18.57 22.5695 18.2579 22.4145L11.7995 19.1845L5.3412 22.4178C5.02912 22.5736 4.73837 22.5409 4.46879 22.3195C4.19929 22.098 4.11062 21.8192 4.20287 21.4828L6.14954 14.3411L0.894537 9.08446C0.653621 8.84363 0.590538 8.5628 0.705371 8.24205C0.820204 7.9213 1.04712 7.7443 1.3862 7.71113L7.76287 7.0728L11.0779 0.444464C11.2271 0.147297 11.468 -0.000869237 11.8005 -0.000119237C12.133 0.000630763 12.3733 0.149964 12.5212 0.447798Z" />
				</symbol>
			</defs></svg>

			<h2 className="text-center mt-0 md:mt-2 mb-1 d:mb-4 md:mb-6">Over 19,000 reviews</h2>
			
			<Container>
				<div className="flex flex-col md:flex-row items-center justify-center gap-1 mb-5">
					<p className="text-center text-is-black-80 mt-2 mr-2 mb-1 md:mb-0">With an average star rating of <strong>{total}</strong></p>
					<div className="flex">
						{Array(Math.floor(total)).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is-blue"></use></svg>)}
						{perc ? <svg className="h-6 w-6"><use href="#star" mask="url(#perc)" className="fill-is-blue"></use></svg> : null}
						{Array(stars_empty).fill().map((el, i) => <svg key={i} className="h-6 w-6"><use href="#star" className="fill-is-blue-50"></use></svg>)}
					</div>
				</div>
		
				<div className={cls("grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-4 md:gap-8")}>
					{reviews.map((el, i) =>
						<div key={i} className="relative col-span-1 flex flex-col rounded-md shadow p-6 my-2 items-center justify-between">
						<div className="flex gap-1">{Array(5).fill().map((el,i) => <svg key={i} width="16" height="16" viewBox="0 0 24 24"><use href="#star" fill="#3C96FB"></use></svg>)}</div>
						<div className="relative py-2 px-6">
							<img src={`${img_path}/quotation.svg`} className="absolute left-0 top-4 h-[0.69rem]"/>
							<p className="text-center text-is-black-50 mt-4">{el.text}</p>
							<img src={`${img_path}/quotation.svg`} className="absolute right-0 bottom-4 rotate-180 h-[0.69rem]" />
						</div>
						<p className="text-sm text-right my-4 mt-auto self-end text-is-black-50">- {el.name}</p>
					</div>
					)}
				</div>
			</Container>
		</div>
}

const Tiles =  () => { 
			return <div className="home-section feature-tiles bg-is-gray pt-6 pb-8 sm:pt-8 sm:pb-14">
						<div className="ui container fluid-fixed">
							<div className="section-header ui center aligned header-wrap text-center mx-auto pb-4">
								<h2 className="!mb-4 !md:mb-6 text-is-blue">Secure and backed by real doctors</h2>
							</div>
							<FeatureTiles size="lg"/>
						</div>
					</div>
}

const faq_data = [
	{
		title: 'What is InstantScripts?',
		content: <>
				<p>Founded in Melbourne in 2018, InstantScripts has helped hundreds of thousands of Australians access health and medical services from the comfort of their homes.</p>
				<p>We are 100% owned by Wesfarmers, one of the largest and most respected companies in Australia.</p>
				<p>Accessing prescriptions for everyday medications, speaking to a doctor, obtaining a medical certificate or getting blood test requests are just some of the online services offered by InstantScripts.</p>
				<p>Our large team of Australian-registered doctors work seven days a week and we take great care in providing the highest quality of service to all communities.</p>
				<p>We are proud to be able to support the overall healthcare industry to ensure all Australians get the care they need, when they need it.</p>
			</>
	},
	{
		title: 'How much does it cost?',
		content: <>
				<p>New guidelines by the Medical Board of Australia may affect pricing. <LearnMore/></p>
				<p><Link to="/online-scripts/">Online Prescriptions</Link> from $19</p>
				<p><Link to="/online-medical-certificates/">Medical Certificates</Link> for $19</p>
				<p><Link to="/online-pathology-requests/">Blood Test Referrals</Link> for $20</p>
				<p><Link to="/online-specialist-referrals/">Specialist Referrals</Link> for $20</p>
				<p><Link to="/telehealth-consultations/">Telehealth Consultations</Link> from $49</p>
				<p><Link to="/treatment-plans/">Treatment Plans</Link> from $49</p>
				<p>These prices are subject to change.</p>
			</>
	},
	{
		title: 'Are InstantScripts Services Medicare bulk billed?',
		content: <>
				<p>InstantScripts offers highly competitive flat-fee pricing for medical services that is often cheaper than out-of-pocket expense on Medicare-funded services.</p>
				<p>The Australian Government does not permit Medicare rebates for any telehealth provider where a face-to-face consultation has not previously occurred in the last 12 months. This means you cannot claim your InstantScripts consultation with Medicare.</p>
			</>
	},
	{
		title: 'Who can use InstantScripts?',
		content: <>
			<p>Anyone of any age in Australia with a Medicare card or IHI number can access InstantScripts for <Link to="/telehealth-consultations/">Telehealth Consultations</Link>.</p>
			<p>However for Online Prescriptions, Medical Certificates, Blood Tests and Specialist Referrals, you must be 18 years old or over.</p>
			</>
	},
	{
		title: 'How do I get a prescription online?',
		content: <>
			<p>You can request an <Link to="/online-scripts/">Online Prescription</Link> from InstantScripts for over 300 medications. In some cases, a Telehealth Consultation may be required. Your prescription request will be reviewed by one of our Australian-registered doctors.</p>
			<p>If a prescription is approved, a QR code will be sent to your phone and email address. You can take it to any pharmacy for dispensing.</p>
			<p>For added convenience, you can also choose to have your InstantScripts prescriptions delivered.</p>
			<p>If you're ineligible, we'll recommend you complete a telephone consultation with one of our doctors to understand why.</p>
			</>
	},
	{
		title: 'Are InstantScripts doctors real doctors?',
		content: <>
				<p>You can trust InstantScripts. Every InstantScripts doctor is an AHPRA-registered and fully-insured doctor based in Australia who will adhere to the highest clinical standards.</p>
				<p>Your doctor’s prescriber number will be written on any prescription that is prescribed.</p>
				</>
	},
	{
		title: 'What medications are available through InstantScripts?',
		content: <>
			<p>You can search for your regular medication by visiting our <Link to="/online-scripts/">Online Prescription</Link> service.</p>
			<p>You can then fill out a digital medical consultation form which will be reviewed by one of our Australian-registered doctors.</p>
			<p>If approved, our doctors will issue you a prescription which you can take to any pharmacy or have the medication delivered to your home.</p>
			<p>If your medication is not listed, or you need to speak to a doctor about your condition, you can request a <Link to="/cat/DocCons">Telehealth Consultation</Link></p>
			<p>Our doctors will not prescribe Schedule 8 or Schedule 4D medications. Please do not request an online doctor consultation via our service if you require these medications.</p>
			</>
	},
	{
		title: 'Am I guaranteed to receive a prescription?',
		content: <>
			<p>InstantScripts upholds the highest standards of healthcare. Online Prescriptions will only be issued if deemed appropriate by our doctors.</p>
			<p>There are various reasons why you may be declined access for a prescription, such as your responses to our digital medical consultation, your medical history and more.</p>
			<p>If you would like to speak to a doctor about your condition, you can request a <Link to="/cat/DocCons">Telehealth Consultation</Link> and a doctor will decide if a prescription is safe to provide.</p>
			</>
	},
	{
		title: 'Do you provide prescription repeats?',
		content: <>
			<p>While only single prescriptions are available through <Link to="/online-scripts">Online Prescriptions</Link>, you can still access repeat prescriptions through InstantScripts.</p>
			<p>If you have one of our selected chronic conditions, you can access repeat prescriptions with home delivery through our <Link to="/treatment-plans/">Treatment Plans</Link>.</p>
			<p>For all other conditions, you can discuss repeat prescriptions with a doctor by requesting a <Link to="/cat/DocCons/">Telehealth Consultation</Link>.</p>
			</>
	},
	{
		title: 'What happens once my prescription is approved?',
		content: <>
				<p>Once your prescription is approved, a QR code will be sent to your mobile phone and email that you can take to any pharmacy for dispensing of your medication.</p>
				<p>If you requested delivery, the medication will be delivered to your door in the time frame notified when ordering your medication.</p>
			</>
	},
	{
		title: 'Can I get a Medical Certificate online using InstantScripts?',
		content: <>
				<p>Yes, a doctor can issue you with a <Link to="/online-medical-certificates/">Medical Certificate</Link> if deemed suitable. The medical certificate will be emailed to you after a doctor has reviewed your digital consultation.</p>
				<p>For medical certificates required for more than 1 day, patients must consult with a doctor through a <Link to="/cat/DocCons/">Telehealth Consultation</Link>.</p>
				</>
	},
	{
		title: 'Can I get a backdated Medical Certificate?',
		content: <>
			<p>Our online <Link to="/c/pharm/certs/">Medical Certificates</Link> can only be issued for the day that it is requested. Our doctors cannot backdate the date that the certificate is issued.</p>
			<p>However, the doctor may consider providing a certificate to cover a specified time period that you have been unwell for, via a <Link to="/cat/DocCons/">Telehealth Consultation</Link>.</p>
			</>
	}
]

const HomepagePatient = ({publicView=false, about=false}) => {

	const user = useUser()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        searchParams.get('oidc') && user_model.onepass_login(true);
    }, []);

	if (app.settings.is_ionic && !app.state.loaded) {
			return <Loader active />
	}

	const user_in = user.user_in

	return <div className={['home-iscr',about?'about':''].join(' ')}>
		{!__IONIC__ && <Hero user_in={user_in && !publicView} about={about}/>}

		<Partners />

		{!about && <CTA user_in={user_in && !publicView}/>}
		
		<div className={cls("home-section-extras", user_in&&"user")}>

		<Testimonials />

		<ConditionsAndServicesTiles showCommon={true} exclCond={"Asthma"} cmnTitle={<h2 className="text-center mb-4 md:mb-6">Common conditions we treat</h2>}/>

		<ImageTextBanner/>

		<Tiles/>

		<HIW/>

		<FAQ data={faq_data} readmore clsn="bg-is-gray" faq_cls="bg-none"/>

		{about && user_in && <CTA  user_in={user_in}/>}
			{/* user_in &&  <UserReferral fancy />*/}
		</div>
	  
	</div>
};

export default HomepagePatient;
