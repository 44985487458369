import React, { Component } from 'react';
import {
	Menu,
	} from 'semantic-ui-react'

import user_model from 'models/user_model'
import org_model from 'models/org_model'
import { Link } from 'react-router-dom';
import GetStarted from 'views/iprep/GetStarted';
import Counter from '../xAppLib/UIelems/Counter';
import ShopCartCount from '../views/prods/ShopCartCount';
import device_model from 'models/device_model';


const _MODEL_NAME = 'menu_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  


export default class menu_model {

	static get MODEL_NAME() { return _MODEL_NAME; }

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	static get_megamenu(inSideMenu) {

		return [
				{
					name:'What we treat',
					key: 'treat', 
					link: '#',
					cls: inSideMenu ? 'flex __menu-sm:hidden' : 'hidden menu-sm:flex',
				},
				{
					name:'What we do',
					key: 'do', 
					link: '#',
					cls: inSideMenu ? 'flex __menu-sm:hidden' : 'hidden menu-sm:flex',
				},
				{
					name:'About us',
					link:'#',
					key: 'about',
					cls: inSideMenu ? 'flex' : 'hidden menu-lg:flex',
				},
			];
	}

	static get_ic_side_menu(inSideMenu) {
		const canPrescribe =
			(app.acl.is_nurse || app.acl.is_leg_oper || app.acl.is_doc_presc) &&
			org_model.org_conf_itm(app.user.org, 'meds') &&
			org_model.org_conf_itm(app.user.org, 'meds') != 'pharm' &&
			user_model.check_access('pg_med', org_model.org_conf_itm(app.user.org, 'meds')) &&
			!app.acl.is_admin;
		const isLegOper = app.acl.is_leg_oper;
		const canViewScripts =
			(app.acl.is_admin || app.acl.is_supp || app.acl.is_doc_gp || app.acl.is_nurse || app.acl.is_NP || (app.acl.is_mang && !['maas', 'spon', 'patho_partner'].includes(app.user.org?.type))) &&
			!app.settings.ileg;
		const isNurseAndHigher = app.acl.is_admin || app.acl.is_doc_gp || app.acl.is_mang || app.acl.is_nurse || app.acl.is_NP;
		const canAccessInventory = user_model.check_access('inventory:view');
		const canAccessDrugBook = user_model.check_access('drug-book:view');
		const canViewProdTrans =
			app.user.claims &&
			((app.user.org?.type == 'cosm' && app.user.org?.conf?.prods_sale?.includes('med_cosm')) || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm') || app.acl.is_admin);
		const canShop =
			(app.acl.is_admin || app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')) && user_model.check_access('buy_prods');
		const canUserAdmin = user_model.check_access('user_admin');
		const canTestVid = app.acl.is_admin || app.acl.is_nurse || app.acl.is_doc;
		const canBook = app?.site_status?.booking?.show_org_booking_menu && app?.user?.org?.type === 'cosm' && (app.acl.is_mang || app.acl.is_nurse);
		const isCosmDevice = device_model.check_access('cosm');
		const medicalEmergencyHdr = `If you've encountered an unexpected reaction or medical emergency, please request immediate medical help by sending this form. Doctor will call you ASAP.`;


		return [
			...((canShop && app.settings.is_mob_sz) ? [{ name: <ShopCartCount text="Cart" />, link: '/products/cart'}] : []),
			...(canUserAdmin ? [{ name: 'User admin', link: '/admin/users' }] : []),
			{
				name: 'Scripting',
				link: canPrescribe && !isLegOper && org_model.new_script_route(),
				sublinks: [
					...((canPrescribe &&
						(isLegOper
							? [
									{
										name: 'New Consult',
										link: `${app.consts.routes.cat_route}/${org_model.org_conf_itm(app.user.org, 'meds')}`
									}
							  ]
							: [
									{
										name: 'New Treatment Request',
										link: org_model.new_script_route()
									},
							  ])) ||
						[]),
						...(app.acl.is_NP ? [{
							name: 'Minor Complication',
							act: () => app.trigger(app.events.SHOW_POPUP, {
								txt: 'Minor Complication',
								pn: 'minor-complication',
								noCloseBtn: true,
								cst_modal: true
							})
						},
						{
							name:'Cosm WR',
							link:'/instcons_wr/cosm'
						}] :[]),
					...(canViewScripts
						? [
								{
									name: 'All Scripts',
									link: '/scripts/list'
								},
								{
									name: 'Treatment Plans',
									link: '/treatments'
								}
						  ]
						: []),
					...(isNurseAndHigher
						? [
								{
									name: 'Patient search',
									link: '/patients'
								}
						  ]
						: []),
						...(canTestVid ? [{ name: 'Test Vidcall', link: '/vidcall-troubleshooting'}] : []),
				]
			},
			...(canShop
				? [
						{
							name: 'Shop products',
							link: '/products/shop',
							activeLinks: ['/products/*'],
							excludeLinks: ['/products/stats']
						}
				  ]
				: []),
				canBook && (
					{
						name: 'Booking',
						sublinks: [
							...(isNurseAndHigher
								? [
										{
											name: 'Book patient',
											link: '/patients'
										}
									]
								: []),
							...(app.acl.is_nurse ? [
								{
									name: 'My Roster',
									link: '/org-booking/roster'
								},
								{
									name: 'My Appointments',
									link: '/org-booking/appointments'
								},
							] : []),
							...(app.acl.is_mang ? [
								{
									name: 'Roster Users',
									link: '/admin/org-booking/users'
								},
								{
									name: 'Appointments Admin',
									link: '/admin/org-booking/appointments'
								},
							] : []),
						]
					}
				),
			...(canAccessInventory
				? [
						{
							name: 'Inventory',
							link: '/inventory'
						}
				  ]
				: []),
			...(canAccessDrugBook
				? [
						{
							name: 'Drug book',
							link: '/drugbook'
						}
				  ]
				: []),
			!app.acl.is_usr && {
				name: 'My orders',
				link: canViewProdTrans && '/trans/list',
				sublinks: [
					...(canViewProdTrans
						? [
								{
									name: 'Transactions list',
									link: '/trans/list'
								}
						  ]
						: []),
					{
						name: 'Pay Us',
						link: '/payment'
					}
				]
			},
			{
				name: 'Statistics',
				sublinks: [
					...(isNurseAndHigher
						? [
								{
									name: 'Script statistics',
									link: '/scripts/stats'
								}
						  ]
						: []),
					...((canShop && [
						{
							name: 'Product statistics',
							link: '/products/stats'
						}
					]) ||
						[])
				]
			},
			{
				name: 'Audits',
				sublinks: [
					...(app.acl.is_nurse
						? [
								{
									name: 'Practitioner Audits',
									link: '/cosm/audit/list/practitioner'
								}
						  ]
						: []),
					...(app.acl.is_mang
						? [
								{
									name: 'Clinic Audits',
									link: '/cosm/audit/list/clinic'
								}
						  ]
						: []),
				]
			},
			{
				name: 'Documents',
				sublinks: [
					...((app.acl.is_nurse || app.acl.is_mang)
						? [
							{
								name:'Policies & Procedures',
								link: '/documents/cosm_pp'
							}
						  ]
						: []),
						
				]
			},
			...(canPrescribe && !isLegOper ? [{
				name: 'Help',
				sublinks: [
					{ name: 'Major complications', link: `${app.consts?.routes?.med_route || 'm'}/${org_model.org_conf_itm(app.user.org, 'meds')}/DocConsCosmMajor` },
					{ name: 'Minor complications', link: `${app.consts?.routes?.med_route || 'm'}/${org_model.org_conf_itm(app.user.org, 'meds')}/DocConsCosm` },
					{ name: 'Contact us', act: () => app.trigger(app.events.SHOW_POPUP, { tp: 'pu', pt: 'contact', txt: 'Contact Us', u: 'contact-us' }) },
				]
			}] : []),
		].filter((item) => item?.link || item?.sublinks?.length);
	}

	//	------------------------	------------------------	------------------------

	static get_menu(inSideMenu, curr_page, unseen_count = 0, unseen_count_express = 0, inMegaMenu =  false) {
		const canPrescribe =
			(app.acl.is_nurse || app.acl.is_leg_oper || app.acl.is_doc_presc) &&
			org_model.org_conf_itm(app.user.org, 'meds') &&
			org_model.org_conf_itm(app.user.org, 'meds') != 'pharm' &&
			user_model.check_access('pg_med', org_model.org_conf_itm(app.user.org, 'meds')) &&
			!app.acl.is_admin;
			const isLegOper = app.acl.is_leg_oper;
			const isCosmApp = app.settings?.icosm;

		return [
			// (inSideMenu || !app.user.user_in || (app.user.user_in && app.user.claims && 
			// 					!( app.acl.is_admin 
			// 						|| app.user.claims.doc 
			// 						|| app.acl.is_mang 
			// 						|| app.acl.is_nurse 
			// 						|| app.acl.is_pharm )
			// 				)) && 

			(inSideMenu ) && !org_model.org_conf_itm(app.runtime.org, 'hide_about') &&  !app.settings.icosm &&
			{
				name:'Home',
				icon:'home',
				link:'/'
			},
			
			(inSideMenu && app.user.user_in) && 
			{
				name:'My profile',
				icon:'user',
				link:'/profile'
			},
			
			(inSideMenu && app.acl.has_history && !app.acl.is_leg_oper && !app.settings.icosm) && 
			{
				name:'Order history & reorder',
				icon:'list alternate outline',
				link:'/history'
			},

			(inSideMenu && app.user.user_in) && 
			{
				type:'separator'
			},
			app.acl.is_NP && !app.settings.icosm &&
			{
				name: 'Minor Complication',
				icon:'medkit',
				act: () => app.trigger(app.events.SHOW_POPUP, {
					txt: 'Minor Complication',
					pn: 'minor-complication',
					noCloseBtn: true,
					cst_modal: true
				}),
				cls:'cta_hl',
			},
			...((app.acl.is_leg_oper || app.acl.is_doc_presc) &&
			org_model.org_conf_itm(app.user.org, 'meds') && org_model.org_conf_itm(app.user.org, 'meds')!='pharm' &&
			user_model.check_access('pg_med', org_model.org_conf_itm(app.user.org, 'meds')) &&
			!app.acl.is_admin && (app.acl.is_leg_oper
					? [{
						name: 'New Consult',
						icon: 'medkit',
						link: `${app.consts.routes.cat_route}/${org_model.org_conf_itm(app.user.org, 'meds')}`,
						cls: 'cta_hl',
					}]
					: [{
						name: 'New Script Request',
						icon: 'medkit',
						link: org_model.new_script_route(),
						cls: 'cta_hl',
					}].concat(inSideMenu && app.settings.is_multiscript_avail && !app.settings.want_multiscript && {
						name: 'Multi-script Request (New)',
						icon: 'medkit',
						link: '/treatments/create',
						cls: 'cta_hl',
					})
			) || []),

			(app.acl.is_admin 
				|| app.acl.is_doc_gp) &&
			{
				menu:'Doctor Consults',
				icon:'doctor',
				items:[

					{
						name:'GP DocCons WR',
						icon:'doc user',
						link:'/instcons_wr/gp'
					},
					{	"type": "separator"			},

					user_model.check_access('view_static_wr') &&
					{
						name:'Awaiting Consult',
						icon:'doctor',
						link:'/scripts/list/await_consult'
					},

					user_model.check_access('doc_noti') &&
					{
						name:'Consults Notifications',
						icon:'announcement',
						link:'/docnoti'
					},
					{
						name:'Consult History',
						icon:'stethoscope',
						link:'/scripts/list/consult_list'
					},
                    user_model.check_access('view_full_wr') && {
						name:'Waiting Room Full List',
						icon:'doctor',
						link:'/scripts/list/await_consult_all'
					},
					{
						name:'Doctors Medicare Stats',
						icon:'chart bar outline',
						link:'/medicare/stats'
					},
					{
						name:'Results and Followup',
						icon:'file',
						link:'/results_followup_all',
						counter: () => <Counter value={unseen_count + unseen_count_express} inline={true} hide_zero={true} />
					},
					{
						name:'Results and Followup (legacy)',
						icon:'file',
						link:'/results_followup',
						counter: () => <Counter value={unseen_count} inline={true} hide_zero={true} />
					},
					{
						name:'MIMS online',
						link:'/docs/mims-online'
					},
					{
						name:'Therapeutic Guidelines',
						link:'/docs/tg'
					},
					{	"type": "separator"			},
					{
						name:'InstCons cosm WR',
						icon:'inject',
						link:'/instcons_wr/cosm'
					},
					
				]
			},

			...(app.acl.is_supp || (app.acl.is_doc && !app.acl.is_doc_gp)) &&
			[
				...(!app.acl.is_NP ? [{
					name:'GPs WR',
					icon:'inject',
					link:'/instcons_wr/gp'
				}] : []),
				...(app.acl.is_NP ? [{
					name:'Cosm WR',
					icon:'inject',
					link:'/instcons_wr/cosm'
				}] : []),
			] || [],

			!inSideMenu && app.user.user_in && (app.acl.is_admin
							|| org_model.org_conf_itm(app.user.org, 'is_mb_disp')) && 
			{
				menu:'MedBuy',
				icon:'shopping basket',
				items:[
					{
						name:'MedBuy Orders New',
						icon:'calendar check outline',
						link:'/scripts/list/medbuy_new'
					},
					{
						name:'MedBuy Orders to Post',
						icon:'send',
						link:'/scripts/list/medbuy_post'
					},
					{
						name:'MedBuy All Orders',
						icon:'shopping basket',
						link:'/scripts/list/medbuy'
					},

					{	"type": "separator"			},

					{
						"name": "Manage your InstantScripts MedBuy prices",
						"link": "/pharm/mb_prices",
						// "up": {"txt": "Check medbuy prices from G Sheet https://docs.google.com/spreadsheets/d/1qiD7WFrRx4MusoYFxlMjR2O5Pbodyc1tdJp6z0fr25U"},
						"icon": "database"
					},

				]
			},

			... app.settings.iscr && (!app.settings.is_mob_sz || inSideMenu) && 
			false &&
			(
				(app.acl.is_not_usr && !app.acl.is_pharm) || 
				(app.acl.is_usr && user_model.check_access('pg_cat'))
			) && 
			[
				{
					name:inSideMenu && 'Request medication' || 'Scripts',
					icon:'medkit',
					link:'/m/pharm/online/prescription',
					// cls: 'md:collapse sm:collapse',
				},

				{
					name:inSideMenu && 'Request Medical Certificate' || 'Certificates',
					icon:'medkit',
					link:'/c/pharm/certs/',
					// cls: 'flex hidden lg:block',
				},

				inSideMenu && {
					name:inSideMenu && 'Pathology request' || 'Pathology',
					icon:'lab',
					link:'/c/pharm/patho',
				},

				!org_model.org_conf_itm(app.runtime.org, 'hide_consults') && {
					name:inSideMenu && 'Speak to a doctor' || 'Consultation',
					icon:'user doctor',
					link:'/cat/DocCons'
				},

				/*inSideMenu && {
					name:inSideMenu && 'Pathology request' || 'Pathology',
					icon:'lab',
					link:'/c/pharm/patho',
				},

				!org_model.org_conf_itm(app.runtime.org, 'hide_consults') && {
					name:inSideMenu && 'Speak to a doctor' || 'Consultation',
					icon:'user doctor',
					link:'/cat/DocCons'
				},*/

				{
					name: 'News',
					link: 'https://www.instantscripts.com.au/blog',
					_targ: '_blank',
				},

				{
					menu:'About Us',
					icon:'',
					items:[

						{
							name: "Our story",
							link: "https://www.instantscripts.com.au/about-us/",
							_targ: '_blank',
						},
						{
							name: "FAQ",
							link: "https://www.instantscripts.com.au/faq",
							_targ: '_blank',
						},
						{
							name: "Help",
							link: "https://help.instantscripts.com.au/en/",
							_targ: '_blank',
						},
						{
							name: "Careers",
							link: "https://www.instantscripts.com.au/careers",
							_targ: '_blank',
						},
						{
							name: "Pharmacies",
							link: "https://www.instantscripts.com.au/for-pharmacies",
							_targ: '_blank',
						},
						/*{
							name: "Testimonials",
							link: "https://www.instantscripts.com.au/customer-testimonials",
							_targ: '_blank',
						},*/
						{
							name: "Privacy",
							link: "https://www.instantscripts.com.au/privacy",
							_targ: '_blank',
						},
						{
							name: "Contact Us",
							link: "/contact-us",
						},

						{	
							type: "header",	
							name: "Our brands"
						},

						{
							name: "InstantCosmetics",
							link: "https://www.instantcosmetics.com.au/",
							targ: '_blank',
						},
						{
							name: "HIVPrevent",
							link: "https://hivprevent.com.au/",
							targ: '_blank',
						},
						{
							name: "Benicura",
							link: "https://benicura.com.au/",
							targ: '_blank',
						},
					]
				},

			] || [],
			

			(!inSideMenu && app.acl.has_history && !app.settings.iprep && !app.settings.ileg && !app.settings.icann && !app.settings.icosm) && 
			{
				name:'History',
				icon:'list alternate outline',
				link:'/history'
			},
			
			/*app.settings.iscr && (
				(app.acl.is_not_usr && !app.acl.is_pharm && inSideMenu) || 
				(inSideMenu && app.acl.is_usr && user_model.check_access('pg_cat'))
			) && 
			{
				name:'Medical certificate',
				icon:'user doctor',
				link:'/m/pharm/certs/med-cert'
			},
			
			(inSideMenu && app.acl.is_usr) && app.settings.iscr &&
			{
				type:'separator'
			},

			app.settings.iscr && (
				(app.acl.is_not_usr && !app.acl.is_pharm && inSideMenu) || 
				(inSideMenu && app.acl.is_usr)
			) && !org_model.org_conf_itm(app.runtime.org, 'hide_about') &&
			{
				name:'About',
				icon:'info',
				link:'/about'
			},*/
			

			(inSideMenu && app.acl.is_usr) && app.settings.iscr &&
			{
				name: 'Contact Us',
				icon: 'question circle',
				// link:'/contact-us',
				act: _ => app.trigger(app.events.SHOW_POPUP, {pt:"contact",	txt:'Contact Us', u:'contact-us'}),
			},


			// user_model.check_access('pts') && app.acl.is_usr &&
			// {
			// 	name:'My History',
			// 	icon:'list alternate outline',
			// 	link:'/history'
			// },


			(app.acl.is_admin || app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')) && inSideMenu
			&&
			{
				menu:'Cosmetic Products',
				icon:'',
				items:[
					...((app.acl.is_admin || app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')) && user_model.check_access('buy_prods') &&
					[
						{
						name:'Aesthetic Products',
						icon:'shopping basket',
						link:'/products/shop'
					},
					{
						name:'Products Statistics',
						link:'/products/stats'
					}
				] || []
				),
					app.user.claims && 
					   (app.user.org?.type=='cosm' && app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')
							|| app.acl.is_admin) &&
					{
						name:'Transactions List',
						icon:'list',
						link:'/trans/list'
					},
					(user_model.check_access('inventory:view')) && {
						name:'Inventory',
						icon:'list',
						link:'/inventory'
					},
					(user_model.check_access('inventory:view')) && {
						name:'Drug Book',
						icon:'book',
						link:'/drugbook'
					},
					{
						name:'Pay Us',
						icon:'list',
						link:'/payment'
					},
					...(inSideMenu ? [{	name: 'Cart', link:'/products/cart', }] : [] ),
				]
			},
			
			(app.acl.is_pharm || app.acl.is_doc || app.acl.is_leg_oper || app.acl.is_mang && ['maas', 'spon'].includes(app.user.org?.type)) && !app.settings.icosm &&
					{
						name:(app.acl.is_leg_oper ? 'All Records' : 'All Scripts'),
						icon:'lab',
						link:'/scripts/list'
					},
			(app.acl.is_pharm || app.acl.is_doc || app.acl.is_leg_oper) && user_model.check_access('pts_srch') && !app.settings.icosm &&
					{
						name:app.acl.is_doc_admin && 'Script Patients' || app.acl.is_leg_oper && 'Client Search' || 'Patient Search',
						icon:'users',
						link:'/patients'
					},

			(app.acl.is_pharm || app.acl.is_doc || app.acl.is_mang && ['maas', 'spon'].includes(app.user.org?.type)) && !app.settings.icosm &&
					{
						name:'Scripts Stats',
						icon:'chart bar',
						link:'/scripts/stats'
					},

			(app.acl.is_mang && ['patho_partner'].includes(app.user.org?.type)) &&
					{
						name:'Pathology Stats',
						icon:'chart bar',
						link:'/patho/stats/pathoreqs'
					},

			(org_model.org_conf_itm(app.user?.org, 'give_discount') && app.acl.is_mang) && 
					{
						menu:'Discounts',
						items:[
							{
								name:'Discounts',
								icon:'lab',
								link:'/admin/discounts'
							},
						]
					},

			(app.acl.is_admin || app.acl.is_supp || app.acl.is_doc_gp || app.user.org?.type === 'cosm' && (app.acl.is_mang || app.acl.is_nurse) && inSideMenu || app.acl.is_mang && !['maas', 'spon', 'patho_partner', 'cosm'].includes(app.user.org?.type)) && !app.settings.ileg &&
			{
				menu:'View Scripts',
				icon:'',
				items:[
					{
						name:'All Scripts',
						icon:'lab',
						link:'/scripts/list'
					},
					app.acl.is_cosm && ({
						name:'Treatment Plans',
						icon:'lab',
						link:'/treatments'
					}),

					...(app.acl.is_admin || app.acl.is_doc_gp || app.acl.is_mang || app.acl.is_nurse) &&
					[
						{
							name:'Patient search',
							icon:'users',
							link:'/patients'
						},

						{
							name:'Scripts Stats',
							icon:'chart bar',
							link:'/scripts/stats'
						},
					] || [],

					...(app.acl.is_admin || app.acl.is_supp) &&
					[
						{	"type": "separator"			},
						{
							name:'All Scripts - ALL FIELDS',
							icon:'lab',
							link:'/scripts/list/support'
						},
						{
							"name": "Script List - ADD RESULTS",
							"link": "/scripts/list/add_results",
							"icon": "target"
						},

						{	"type": "separator"			},
						{	
							"type": "header",
							"name": "Subset lists",
							icon: 'shopping basket',
						},

						{
							"name": "Script List - App Scripts",
							"link": "/scripts/list/medcons",
							"icon": "mobile"
						},
						{
							"name": "Script List - Doctor Consultation",
							"link": "/scripts/list/doccons",
							"icon": "doctor"
						},
						{
							"name": "Script List - All Paid",
							"link": "/scripts/list/paid",
							"icon": "dollar"
						},
						{
							"name": "Script List - awaits approval",
							"link": "/scripts/list/approve",
							"icon": "check"
						},

						{	"type": "separator"			},
						{	
							"type": "header",
							"name": "Medbuy",
							icon: 'shopping basket',
						},

						{
							name:'MedBuy New',
							icon:'calendar check outline',
							link:'/scripts/list/medbuy_new'
						},
						{
							name:'MedBuy to Post',
							icon:'send',
							link:'/scripts/list/medbuy_post'
						},
						{
							name:'MedBuy All',
							icon:'shopping basket',
							link:'/scripts/list/medbuy'
						},

						{	"type": "separator"			},
						{	
							"type": "header",
							"name": "Doctor Consults",
							icon: 'doctor',
						},
						{
							name:'Consult History',
							icon:'stethoscope',
							link:'/scripts/list/consult_list'
						},
						{
							name:'Waiting Room Full List',
							icon:'doctor',
							link:'/scripts/list/await_consult_all'
						},
					] || [],

					...(app.acl.is_admin || app.acl.is_supp) &&
					[
						{	"type": "separator"			},
						{	
							"type": "header",
							"name": "Booking",
							icon: '',
						},
						{
							name:'Roster Periods',
							icon:'calendar alternate outline',
							link:'/admin/booking/roster'
						},
						{
							name:'Roster Users',
							icon:'calendar alternate outline',
							link:'/admin/booking/roster-users'
						},
						{
							name:'Appointments',
							icon:'calendar plus outline',
							link:'/admin/booking/appointments'
						},
					] || [],

					...(app.acl.is_doc) &&
					[
						{
							name: "Manage Prescriptions",
							icon: "list alternate outline",
							link: "/admin/prescs"
						},
						{
							"name": "Outstanding Prescriptions",
							"icon": "unordered list",
							"link": "/admin/prescs/outstanding"
						},
						{
							name: "Manage Treatment Plans",
							icon: "calendar alternate outline",
							link: "/admin/treatmentPlans"
						},
					] || [],
				]
			},

			...(app?.user?.prof?.booking_types?.length > 0 && app?.site_status?.booking?.show_bookable_staff_menu
				? [{
					menu: 'Booking',
					icon: '',
					items: [
						{
							name: 'Roster',
							icon: 'calendar alternate outline',
							link: '/booking/roster'
						},
						...( app?.site_status?.booking?.show_bookable_staff_availability_menu ? [
							{
								name: 'Availability',
								icon: 'calendar outline',
								link: '/booking/availability'
							}
						] : []),
						{
							name: 'Appointments',
							icon: 'calendar plus outline',
							link: '/booking/appointments'
						},
					]
				}] : []),

			// 		--------------------------------		--------------------------------		---------

			app.acl.is_supp &&
			{
				menu:'Support',
				icon:'',
				items:[
					{
						"name": "Manage Organisations",
						"link": "/admin/orgs",
						"icon": "building"
					},
					!app.settings.is_ionic && {
						"name": "Pharmacies self signup",
						"link": "/org-signup",
						"icon": "signup"
					},
					{
						"name": "Review Audits",
						"link": "/cosm/audit/list",
						"icon": "certificate"
					},

					{	"type": "separator"			},
					{
						"name":'User Admin',
						"link":'/admin/users',
						"icon":'user circle',
					},
					{
						"name":'Patient search',
						"link":'/patients',
						"icon":'users',
					},

					{	"type": "separator"			},
					{
						"name": "Manage Discounts",
						"link": "/admin/discounts",
						"icon": "arrow down cart"
					},
					{
						"name": "Manage Docs Roster",
						"link": "/admin/docsroster",
						"ico": "call square"
					},

					{	"type": "separator"			},
					{
						"name": "View Prescriptions",
						"link": "/admin/prescs",
						"icon": "list alternate outline"
					},
					{	"type": "separator"			},
					{
						"name": "Medicare Claims",
						"link": "/scripts/list/medicare",
						"icon": "check"
					},
					app.acl.is_supp_mang && {
						"name": "Manage Devices",
						"link": "/admin/devices",
						"icon": "arrow down cart"
					},

					{	"type": "separator"			},
					{
						"name": "Cosmetic Transactions",
						"link": "/trans/list",
						"icon": "syringe"
					},
					{	"type": "separator"			},
					app.acl.is_supp_patho && 
					{
						"name": "Results Processing",
						"link": "/admin/results/dashboard",
						"icon": "lab"
					},
					{
						"name": "Unactioned Followups",
						"link": "/admin/unactioned_followups",
						"icon": "list alternate outline"
					},
				],
			},

			// 		--------------------------------		--------------------------------		---------

			/*(app.acl.is_cosm && user_model.check_access('kb_view', 'cosm')) &&
			{
				menu:'Training',
				icon:'',
				items:[
					{
						name:'Supplier Training/Events',
						icon:'book',
						link:'/kb/cosm'
					},
					
					{
						name:<>Training by ASI (The Aesthetics and Skin Institute)</>,
						icon:'book',
						link:'/cosmtraining'
					},
				]
			},*/

			...(isCosmApp && canPrescribe && !isLegOper && !inSideMenu
				? [
						{
							name: 'New Script',
							link: org_model.new_script_route(),
							cls: 'menu_cta_secondary'
						}
				  ]
				: []),
			// Don't think we need that here for NP
			// ...(isCosmApp && app.acl.is_NP
			// 	? [
			// 		{
			// 			name: 'Minor Complication',
			// 			act: () => app.trigger(app.events.SHOW_POPUP, {
			// 				txt: 'Minor Complication',
			// 				pn: 'minor-complication',
			// 				noCloseBtn: true,
			// 				cst_modal: true
			// 			}),
			// 			cls:'menu_cta_secondary',
			// 		},
			// 	  ]
			// 	: []),


			isCosmApp && !inSideMenu && (app.user.org?.conf?.prods_sale?.includes('med_cosm') || app.user?.prof?.xtra?.prods_sale?.includes('med_cosm')) && user_model.check_access('buy_prods') &&
			{
				name: <ShopCartCount text="Cart" />,
				link:'/products/cart',
				cls: 'cta_quaternary',
			},

			(app.acl.is_admin || app.acl.is_mark) &&
			{
				menu:'Stats',
				icon:'',
				items:[
					{
						"name": "Scripts Stats",
						"link": "/admin/stats/tots",
						"icon": "line graph"
					},
					{
						"name": "Consult Types Stats",
						"link": "/admin/stats/cons_types",
						"icon": "line graph"
					},
					{
						"name": "Consult Types Detailed Stats",
						"link": "/admin/stats/cons_types_det",
						"icon": "line graph"
					},
					{
						"name": "Patients Stats",
						"link": "/admin/stats/tots_pts",
						"icon": "line graph"
					},
					{
						"name": "Users Stats",
						"link": "/admin/stats/usrs_regs",
						"icon": "line graph"
					},
					{
						"name": "GP Stats",
						"link": "/admin/stats/gp_stats",
						"icon": "line graph"
					},
                    {
						"name": "OnePass Stats",
						"link": "/admin/stats/onepass_stats",
						"icon": "line graph"
					},
					{
						"name": "Partners Stats",
						"link": "/admin/stats/partn_scr",
						"icon": "line graph"
					},
					{
						"name": "Doc Scripts Stats",
						"link": "/admin/stats/docs_scr",
						"icon": "line graph"
					},
					{
						"name": "Doc Scripts Pay Stats",
						"link": "/admin/stats/docs_scr_pay",
						"icon": "line graph"
					},
					{
						"name": "DocCons Docs Stats",
						"link": "/admin/stats/docs_cons",
						"icon": "line graph"
					},
					{
						"name": "Consults Pay Stats",
						"link": "/admin/stats/doccons_pay",
						"icon": "line graph"
					},
					{
						"name": "Feedback Stats",
						"link": "/admin/stats/feedback",
						"icon": "comment outline"
					},
					{
						"name": "Doc Skype Stats",
						"link": "/admin/stats/docs",
						"icon": "line graph"
					},
					{
						"name": "Pathology Stats",
						"link": "/admin/stats/pathoreqs",
						"icon": "line graph"
					},
					{
						"name": "Medbuy Stats",
						"link": "/admin/stats/medbuy",
						"icon": "line graph"
					},
					// {
					// 	"name": "Org Script Stats",
					// 	"link": "/admin/stats/orgs_scrs",
					// 	"icon": "line graph"
					// },
					// {
					// 	"name": "Org Script Inv Stats",
					// 	"link": "/admin/stats/orgs_scrs_inv",
					// 	"icon": "line graph"
					// },
					{
						"name": "Campaign Scripts Stats",
						"link": "/admin/stats/camp_scr",
						"icon": "line graph"
					},
					{
						"name": "Campaign Pts Stats",
						"link": "/admin/stats/camp_pts",
						"icon": "line graph"
					},
					{
						"name": "Referral Scripts Stats",
						"link": "/admin/stats/refr_scr",
						"icon": "line graph"
					},
					{
						"name": "Referral Pts Stats",
						"link": "/admin/stats/refr_pts",
						"icon": "line graph"
					},
					{
						"name": "Treatment Plan Stats",
						"link": "/admin/stats/treatment_plan",
						"icon": "line graph"
					},
					{
						"name": "Express Services Stats",
						"link": "/admin/stats/expr",
						"icon": "line graph"
					},
					{
						"name": "Script Error Stats",
						"link": "/admin/stats/errors",
						"icon": "line graph"
					},
					{
						"name": "Evermed Orders",
						"link": "/admin/stats/evermed",
						"icon": "line graph"
					},
					{
						"name": "Unseen Patho Results",
						"link": "/admin/stats/unseen_patho",
					},
					{
						"name": "Cosm. Wait Times",
						"link": "/admin/stats/cosm_wait",
						"icon": "line graph"
					},
					{
						"name": "Seen Express Results",
						"link": "/admin/stats/seen_express_path",
					},
					
				]
			},
			// 		{
			// 			"name": "Stats - total monthly scripts",
			// 			"link": "/admin/stats/tots_month",
			// 			"icon": "line graph"
			// 		},
			// 		{
			// 			"name": "Stats - total yearly scripts +",
			// 			"link": "/admin/stats/tots_year",
			// 			"icon": "line graph"
			// 		},


			// 		{	"type": "separator"			},
			// 		{	
			// 			"type": "header",	
			// 			"name": "Patients Stats"
			// 		},

			// 		{
			// 			"name": "Stats - Pts hourly usage",
			// 			"link": "/admin/stats/tots_pts_hour",
			// 			"icon": "add user"
			// 		},
			// 		{
			// 			"name": "Stats - Pts daily usage",
			// 			"link": "/admin/stats/tots_pts_day",
			// 			"icon": "add user"
			// 		},
			// 		{
			// 			"name": "Stats - Pts weekly usage",
			// 			"link": "/admin/stats/tots_pts_week",
			// 			"icon": "add user"
			// 		},
			// 		{
			// 			"name": "Stats - Pts monthly usage",
			// 			"link": "/admin/stats/tots_pts_month",
			// 			"icon": "add user"
			// 		},
			// 		{
			// 			"name": "Stats - Pts yearly usage",
			// 			"link": "/admin/stats/tots_pts_year",
			// 			"icon": "add user"
			// 		},

			// 		{	"type": "separator"			},
			// 		{	
			// 			"type": "header",	
			// 			"name": "Users Stats"
			// 		},

			// 		{
			// 			"name": "Users - totals user regs hourly",
			// 			"link": "/admin/stats/usrs_regs_hour",
			// 			"icon": "users"
			// 		},
			// 		{
			// 			"name": "Users - totals user regs daily",
			// 			"link": "/admin/stats/usrs_regs_day",
			// 			"icon": "users"
			// 		},
			// 		{
			// 			"name": "Users - totals user regs weekly",
			// 			"link": "/admin/stats/usrs_regs_week",
			// 			"icon": "users"
			// 		},
			// 		{
			// 			"name": "Users - totals user regs monthly",
			// 			"link": "/admin/stats/usrs_regs_month",
			// 			"icon": "users"
			// 		},
			// 		{
			// 			"name": "Users - totals user regs yearly",
			// 			"link": "/admin/stats/usrs_regs_year",
			// 			"icon": "users"
			// 		},

			// 		{	"type": "separator"			},
			// 		{	
			// 			"type": "header",	
			// 			"name": "Partners Stats"
			// 		},

			// 		{
			// 			"name": "Partners - total hourly scripts",
			// 			"link": "/admin/stats/partn_scr_hour",
			// 			"icon": "building outline"
			// 		},
			// 		{
			// 			"name": "Partners - total daily scripts",
			// 			"link": "/admin/stats/partn_scr_day",
			// 			"icon": "building outline"
			// 		},
			// 		{
			// 			"name": "Partners - total weekly scripts",
			// 			"link": "/admin/stats/partn_scr_week",
			// 			"icon": "building outline"
			// 		},
			// 		{
			// 			"name": "Partners - total monthly scripts",
			// 			"link": "/admin/stats/partn_scr_month",
			// 			"icon": "building outline"
			// 		},
			// 		{
			// 			"name": "Partners - total yearly scripts",
			// 			"link": "/admin/stats/partn_scr_year",
			// 			"icon": "building outline"
			// 		},

			// 	]

			// },

			(app.user?.prof?.xtra?.show_docs_scr_stats || app.acl.is_supp && app.user.claims.mang) &&
			{
				menu:'Docs Stats',
				icon:'',
				items:[
					{
						"name": "Manage Docs Roster",
						"link": "/admin/docsroster",
						"ico": "call square"
					},

					{	"type": "separator"			},
					{
						"name": "Stats - docs scripts daily",
						"link": "/admin/stats/docs_scr_day",
						"ico": "pie graph"
					},
					{
						"name": "Stats - docs scripts weekly",
						"link": "/admin/stats/docs_scr_week",
						"ico": "pie graph"
					},
					{
						"name": "Stats - docs scripts monthly",
						"link": "/admin/stats/docs_scr_month",
						"ico": "pie graph"
					},
					{
						"name": "Stats - docs scripts yearly",
						"link": "/admin/stats/docs_scr_year",
						"ico": "pie graph"
					},
				]
			},

			(app.acl.is_admin || app.acl.is_mark) &&
			{
				menu:'Mark',
				icon:'',
				items:[

					{	
						"type": "header",	
						"name": "Campaigns Scripts Stats"
					},

					{
						"name": "Campaigns - scripts daily",
						"link": "/admin/stats/camp_scr_day",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - scripts weekly",
						"link": "/admin/stats/camp_scr_week",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - scripts monthly",
						"link": "/admin/stats/camp_scr_month",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - scripts yearly",
						"link": "/admin/stats/camp_scr_year",
						"icon": "Campaigns"
					},

					{	
						"type": "header",	
						"name": "Campaigns Pts Stats"
					},

					{
						"name": "Campaigns - pts daily",
						"link": "/admin/stats/camp_pts_day",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - pts weekly",
						"link": "/admin/stats/camp_pts_week",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - pts monthly",
						"link": "/admin/stats/camp_pts_month",
						"icon": "Campaigns"
					},
					{
						"name": "Campaigns - pts yearly",
						"link": "/admin/stats/camp_pts_year",
						"icon": "Campaigns"
					},

					{	"type": "separator"			},
					{	
						"type": "header",	
						"name": "Referral Scripts Stats"
					},

					{
						"name": "Referral - scripts daily",
						"link": "/admin/stats/refr_scr_day",
						"icon": "Referral"
					},
					{
						"name": "Referral - scripts weekly",
						"link": "/admin/stats/refr_scr_week",
						"icon": "Referral"
					},
					{
						"name": "Referral - scripts monthly",
						"link": "/admin/stats/refr_scr_month",
						"icon": "Referral"
					},
					{
						"name": "Referral - scripts yearly",
						"link": "/admin/stats/refr_scr_year",
						"icon": "Referral"
					},

					{	
						"type": "header",	
						"name": "Referral Pts Stats"
					},

					{
						"name": "Referral - pts daily",
						"link": "/admin/stats/refr_pts_day",
						"icon": "Referral"
					},
					{
						"name": "Referral - pts weekly",
						"link": "/admin/stats/refr_pts_week",
						"icon": "Referral"
					},
					{
						"name": "Referral - pts monthly",
						"link": "/admin/stats/refr_pts_month",
						"icon": "Referral"
					},
					{
						"name": "Referral - pts yearly",
						"link": "/admin/stats/refr_pts_year",
						"icon": "Referral"
					},

					{	"type": "separator"			},
					{	
						"type": "header",	
						"name": "Feedback"
					},
					{
						"name": "Manage Feedback",
						"link": "/admin/manage_feedback",
						"icon": "comments outline"
					},
					{
						"name": "Manage Feedback Templates",
						"link": "/admin/manage_feedbacktemplates",
						"icon": "comment outline"
					},

					{	"type": "separator"			},
					{	
						"type": "header",	
						"name": "Health Guide"
					},
					{
						"name": "Manage Landing Pages",
						"link": "/admin/landing_pages"
					},
				]

			},

			user_model.check_access('user_admin') && !app.acl.is_cosm && !app.acl.is_supp && (app.settings.icosm ? inSideMenu : true) &&
			{
				menu:'Users Admin',
				icon:'',
				items:[
					{
						name:'User Admin',
						icon:'user circle',
						link:'/admin/users'
					},
				]

			},

			// user_model.check_access('show_contact') &&
			// {
			// 	name: 'Contact Us',
			// 	icon: 'question circle',
			// 	act: _ => app.trigger(app.events.SHOW_POPUP, {pt:"contact",	txt:'Contact Us', u:'contact-us'}),
			// },

			// user_model.check_access('show_contact') &&
			// {
			// 	component:<Feedback trigger={<Menu.Item
			// 			name="Contact"
			// 			// icon="question circle"
			// 		/>} />
			// },

		
			false && app.acl.is_work && !app.acl.is_leg_oper && !org_model.org_conf_itm(app.dvc.org, 'hide_personal') && 
			{
				menu:'My Personal Scripts',
				icon:'',
				items:[
					// app.acl.is_admin &&
					{
						name:'Request Script',
						icon:'medkit',
						link:'/c'
					},
					{
						name:'Request Pathology Test',
						icon:'lab',
						link:'/c/pharm/patho'
					},
					{
						name:'Request Doctor Consultation',
						icon:'user doctor',
						link:'/cat/DocCons'
					},

					// app.acl.is_admin &&
					// {
					// 	name:'New Cosmetic Script',
					// 	icon:'medkit',
					// 	link:'/cat_cosm'
					// },

					{
						name:'My History',
						icon:'list alternate outline',
						link:'/history'
					},
				]
			},

			// ----------------------------------------------

			app.user.claims && app.acl.is_mang && app.user.org.type=='supp' &&
			{
				name:'Transactions List',
				icon:'list',
				link:'/trans/list'
			},


			...(inSideMenu && app.settings.is_ionic && app.app_data.ws_conf.footer.items.map( (it, i)=>({
					component:<Menu.Item 
										content={it.txt}
										name={it.txt} 
										as={it.tp == 'ln' ? Link : 'span'} 
										to={it.u} 
										active={curr_page === it.u} 
										onClick={()=>{
											it.tp=='pu' && app.trigger(app.events.SHOW_POPUP, it)
										}}
										style={{cursor:'pointer'}}
										key={'foot_menu_more'+i}
									  />
							  })) || [] ),

			/*app.settings.icosm &&
			{
				name:'Training',
				icon:'shopping basket',
				link:'/training'
			},
			...(app.settings.icosm && inSideMenu) && icosm_sidemenu  || [],
			app.settings.icosm && app.user.user_in &&

				name:'Cart',
				link:'/products/cart'
			*/
			(app.settings.icosm && inSideMenu && !app.user.user_in && !inMegaMenu) &&
			{
				name:'Join',
				link:'/join'
			},
			app.settings.icosm && !app.user.user_in && inMegaMenu &&
			{
				name:'Growth Journey',
				key:'grow'
			},
			app.settings.icosm && !app.user.user_in && inMegaMenu &&
			{
				name:'Our Support',
				key:'support'
			},
			app.settings.icosm && !app.user.user_in && inMegaMenu &&
			{
				name:'Why IC',
				key:'why'
			},
			app.settings.icosm && !app.user.user_in && inMegaMenu &&
			{
				name:'Resources',
				key:'resources'
			},
			/*app.settings.icosm && !app.user.user_in &&
			{
				name:'Shop',
				link:'/products/sale'
			},
			app.settings.icosm && !app.user.user_in &&
			{
				name:'Login',
				link:'/login'
			},
			app.settings.icosm && !app.user.user_in && inSideMenu &&
			{
				name:'Sign Up',
				link:'/get-started'
			},*/


			app.settings.iprep && app.acl.has_history && !inSideMenu &&
			{
				name:<>Home</>,
				link:'/'
			},	
			app.settings.iprep && app.settings.iprep_avail && !app.acl.has_history &&
			{
				name:<>Discreet <br/>Process</>,
				link:'/#process-overview'
			},	
			app.settings.iprep && app.settings.iprep_avail && !app.acl.has_history &&
			{
				name:<>What is <br/>PrEP?</>,
				link:'/#what-is-prep'
			},
			app.settings.iprep && app.settings.iprep_avail && !app.acl.has_history &&
			{
				name:<>How <br/>much?</>,
				link:'/#how-much'
			},	
			app.settings.iprep && app.settings.iprep_avail && !app.acl.has_history &&
			{
				name:<>Who <br/> are we?</>,
				link:'/#who-are-we'
				  
			},	
			app.settings.iprep && app.settings.iprep_avail &&
			{
				name:<>Speak to <br/>a Doctor</>,
				link:'/#telehealth'
				  
			},	
			app.settings.iprep && (inSideMenu) && 
			{
				name:'FAQ',
				link:'/prep/faq'
			},
			app.settings.iprep &&
			{
				name:'Support',
				link:'/prep/support'
			},
			
			// app.settings.iprep && !app.user.user_in && !inSideMenu && 
			// {
			// 	name:'Log In',
			// 	link:'/login'
			// },
			// app.settings.iprep &&
			// {
			// 	name:'Sign Up',
			// 	link:'/login/signup'
			// },
			
			(app.settings.iprep && app.acl.has_history) && !inSideMenu &&
			{
				name:'History',
				icon:'list alternate outline',
				link:'/history'
			},
			app.settings.iprep && (app.settings.iprep_avail || app.acl.has_history) && !inSideMenu &&
			{
				name:<GetStarted/>,
			  	cls:'get-started sm',
				link:'/application-progress'
			},

			app.settings.icann && app.settings.icann_avail && {
				name: 'For Patients',
				link: '/forpatients'
			},

			app.settings.icann && app.settings.icann_avail && {
				name: 'About Us',
				link: '/aboutus'
			},

			app.settings.icann && {
				name: 'Support',
				link: '/support',
			},

			app.settings.icann && app.settings.is_cmg && {
				name: 'Indications',
				link: '/indications'
			},

			app.settings.icann && app.acl.has_history && !inSideMenu &&
			{
				name:'History',
				icon:'list alternate outline',
				link:'/history'
			},

			app.settings.icann && app.settings.icann_avail && {
				name: 'Get Started',
				link: '/getstarted',
				cls: 'get-started'
			},

			// app.settings.icann && !app.user.user_in && !inSideMenu && {
			// 	name: 'Sign In',
			// 	link: '/login',
			// 	cls: 'sign-in'
			// },


			app.settings.ileg && !app.user.user_in &&
			{
				name:'For Patients',
				link:'/forpatients'
			},
			
			app.settings.ileg && !app.user.user_in &&
			{
				name:'For Lawyers',
				link:'/forlawyers'
			},	

			app.settings.ileg && !app.user.user_in &&
			{
				name:'Register Your Firm',
				link:'/org-signup'
			},	

			app.settings.ileg && !app.user.user_in && !inSideMenu && 
			{
				name:'Login',
				link:'/login'
			},

			// app.settings.ileg && app.user.user_in &&
			// {
			// 	name:'Request Doctor Consultation',
			// 	icon:'doctor',
			// 	link:'/scripts/list'
			// },

			// {
			// 	name:'',
			// 	icon:'',
			// 	link:''
			// },
		]
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

}
