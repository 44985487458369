import React from 'react';
import UniFormMed from 'views/med/UniFormMed';
import { Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import script_model from '../../../../models/script_model';

const HeidiTranscription = (props) => {

    const { Section } = props;
    const is_async = script_model.is_async_consult(props);

	return (
		<UniFormMed {...props} section="heidi-consent" fields={script_model.HEIDI_FIELDS}>
			{(values, valids, uf_this) => {
				return (
					<Section>
						<Section.Header>Medical AI scribe</Section.Header>
						<Section.Content>
                        <div className='flex flex-col gap-2'>
                            <p>
                                By continuing you are agreeing to allow your clinician to use Heidi AI {
                                    is_async
                                        ? 'should the doctor need to contact you regarding your request'
                                        : 'during your consultation'
                                }.
                                <Link
                                    className="text-blue-800 ml-1"
                                    onClick={() => app.trigger(app.events.SHOW_POPUP, { pt: 'cx_txt', pn: 'heidi-consent', noHeader: true })}
                                >Learn more about Heidi here.</Link>
                            </p>
                            <Checkbox
                                toggle
                                checked={props.formData?.consent_to_use_heidi}
                                label='I consent to use Heidi'
                                onChange={() => uf_this.handleInputChange(null, { name: 'consent_to_use_heidi', value: !props.formData?.consent_to_use_heidi })}
                            />
                        </div>
						</Section.Content><br/>
					</Section>
				);
			}}
		</UniFormMed>
	);
};

export default HeidiTranscription;
