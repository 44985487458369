import React, { useState, useRef } from 'react';

import user_model from 'models/user_model';
import { useProfile, useToggleState } from 'xAppLib/Hooks';

import { Modal, Checkbox, Grid, Button, Icon } from 'semantic-ui-react';
import gtm from '../providers/gtm';

const NONE = 'none';

const CHRONIC_TYPES = require('conf/chro_conds_list.json');
const CHRONIC_TYPE_CMG = require('conf/cmg_extra_conds.json');

let extra = [
	{"label": "None", "key": "none" },
	{"label": "Other", "key": "other" }
]
const CHRONIC_TYPE = app.settings.is_cmg && [extra[0], ...CHRONIC_TYPES.concat(CHRONIC_TYPE_CMG).sort((a, b) => a.label.localeCompare(b.label)), extra[1]] || [extra[0], ...CHRONIC_TYPES];

export const ChroCond_keysToLabels = (keys) => {
	return keys?.map((key) => CHRONIC_TYPE.find((c) => c.key === key)?.label ).filter(Boolean);
};

const ChronicConditionsModal = ({ onClose, uf_this, chroValues }) => {
	const [profile] = useProfile();
	const profileConditions = chroValues ?? profile?.chro_cond;
	const userCroCondList = Array.isArray(profileConditions) ? profileConditions.reduce((acc, key) => (acc[key] = true) && acc, {}) : {};
	const [chronicList, setChronicList] = useState(userCroCondList);
	const [isLoading, turnIsLoadingOn, turnIsLoadingOff] = useToggleState();
	const cacheOldValues = useRef();

	const handleCheck = (key) => {
		if (key === NONE) {
			if (!chronicList[key]) {
				cacheOldValues.current = chronicList;
				setChronicList({ [key]: true });
			} else {
				setChronicList(cacheOldValues.current ?? { [key]: false });
			}
			return;
		}
		setChronicList((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	const isDisabled = !Object.entries(chronicList).some(([k, v]) => !(v ? userCroCondList[k] : !userCroCondList[k]));

	const updateProfile = () => {
		if (!isDisabled) {
			const chro_cond = Object.entries(chronicList)
				.filter(([, v]) => v)
				.map(([k]) => k);

			if (uf_this) {
				// Update the form with the new value
				uf_this.setVals({ chro_cond });
				app.trigger(app.events.USER_UPD, 'ProfileUpdated');
				onClose?.();
			} else {
				turnIsLoadingOn();

				const updProfile = {
					...profile,
					chro_cond
				};

				user_model.save_prof(updProfile, (result) => {
					// update old profile
					// https://sentry.io/organizations/instantscripts/issues/3571237141/?project=1286240&query=is%3Aunresolved&statsPeriod=7d
					result && (app.user.prof.chro_cond = result.profile.chro_cond);
					turnIsLoadingOff();
					onClose?.();
				});
			}
			gtm.log('', 'chron_cond_save');
		}
	};

	return (
		<>
			<Modal.Content>
				<Grid>
					{CHRONIC_TYPE.map(({ label, key }) => (
						<Grid.Column key={key} mobile={16} tablet={8} computer={4}>
							<Checkbox disabled={key !== NONE && chronicList[NONE]} label={label} checked={Boolean(chronicList[key])} onChange={() => handleCheck(key)} />
						</Grid.Column>
					))}
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button data-testid='button-cancel' onClick={onClose}>
					<Icon name="undo" /> Cancel
				</Button>
				<Button positive data-testid='button-save' onClick={updateProfile} disabled={isDisabled} loading={isLoading}>
					{uf_this ? 'Confirm' : <><Icon name="save" /> Save</>} 
				</Button>
			</Modal.Actions>
		</>
	);
};

export default ChronicConditionsModal;
