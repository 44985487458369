import React from "react";
import instcons_model from "models/instcons_model";
import VideoConference from "../UIelems/VideoConference";


const UserConsult = ({ row, id, onClose, onPeersConnected, onMediaAuthChange, showControls }) => {
	return (
		<div>
				<div className="text-center">
					<div className="mt-2">
						<VideoConference
							embedded
							showControls={showControls}
							sessionId={row.sessionId}
							roomId={row.twRoomSid}
							provider={row.vid_provider ?? row.default_vid_provider}
							autoStart
							name={`${app.user.prof?.first_name} ${app.user.prof?.last_name}`}
							onMediaAuthChange={onMediaAuthChange}
							onSubmitLog={(label, args) => {
								instcons_model.add_record(`${id}/logs`, {
									...JSON.parse(JSON.stringify(args)), label, tm: Date.now()
								});
							}}
						/>
					</div>
				</div>
		</div>
	);
};

export default UserConsult;
