import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

function byName(left, right) {
	return left.name?.localeCompare(right.name) ?? 0;
}

function bySize(left, right) {
	if (left.size && right.size) {
		// Some meds have multiple active ingredients, so we have sizes like 10/40/20. Big assumption the
		// sizes are in the same order, but it's the best we can do.
		const lSize = left.size.split(/[^\d.]/).map(x => parseFloat(x));
		const rSize = right.size.split(/[^\d.]/).map(x => parseFloat(x));
		const len = Math.min(lSize.length, rSize.length);

		for (let i = 0; i < len; i++) {
			const cmp = lSize[i] - rSize[i];
			if (Number.isFinite(cmp) && cmp !== 0) {
				return cmp;
			}
		}
	}

	return 0;
}

function byQuantity(left, right) {
	if (left.qnty && right.qnty) {
		const lQuantity = parseFloat(left.qnty);
		const rQuantity = parseFloat(right.qnty);

		const cmp = lQuantity - rQuantity;
		if (Number.isFinite(cmp)) {
			return cmp;
		}
	}

	return 0;
}

function sortResults(left, right) {
	return (
		byName(left, right)
		|| bySize(left, right)
		|| byQuantity(left, right)
		|| 0
	);
}

export function Results({results, srch}) {
	const navigate = useNavigate();
	const base = window.location.pathname.replace(/\/prescription\/?$/,'');
	const sorted = results.slice().sort(sortResults);

	const onClick = ({m, t, hdr_warn, price, name}) => {

		if (t === 'l') {
			return navigate(m, {state: {hdr_warn}})
		}

		return navigate(`${base}/${m}`);
	};

	return ( <div className={"ui vertically divided grid"}>
			{sorted.map((result, k) => (
				<Grid.Row key={k} style={{paddingBottom: 0, ...(k > 0 && {paddingTop: 0})}}>
					<Grid.Column tablet={10} computer={10} mobile={10} textAlign="left" className="!mx-0">
						<strong><span className="capitalize">{result.name}</span> {result.size}{result.qnty && ` (x${result.qnty})`}</strong><br/>
						{result.alt_name && `Generic name: ${result.alt_name}`}
						{result?.t == "con" && <span className="text-sm" data-testid="med-search-consult">This medication requires you to book <br className="hidden md:block"/> a consultation for ${app.site_status?.prs_mc[result?.price]}</span>}
					</Grid.Column>
					<Grid.Column tablet={6} computer={6} mobile={6} textAlign="right" className="!pl-0 md:!pl-4">
						<Button compact basic type="button" data-testid="button-select" onClick={() => onClick(result)}>
							{result?.t == "con" ? "Request consultation" : "Select"}
						</Button>
					</Grid.Column>
				</Grid.Row>
			))}
		</div>
	)
}
