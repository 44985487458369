import React, { useCallback, useEffect, useState } from 'react';
import { Container, Segment, Form, Button, Icon, Message, Header } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import user_model from 'models/user_model';
import org_model from 'models/org_model';
import BackButton from 'xAppLib/UIelems/BackButton';

import MedData from './MedData'
import MedForm from './MedForm'
import { 
	CollectionMethod,
	MedicalConsultation,
	ProfileSection,
	ShippingAddressNew,
	PathologyItems,
	PharmacySelect,
	ExtraInfo,
	Summary,
	TreatmentPlan,
	TermPtsDets,
	FormMessage,
	MedUnsuit,
	Payment,
	SkypeButtons,
	Confirmation,
	Messages,
	Signature,
	MedDets,
	HDR,
	CosmTreat,
	CosmDoc,
	ValidationErrors,
	Intro,
	DocCons,
	DocScr,
	YOGP,
	MyHR,
	AltPharm,
	Cado,
	DocCallWarning,
	Disclaimer,
	DocMedicareWarn,
	ClosePopup,
	MedSelection,
	DVASection,
	Fulfilment,
	EvermedDeliverySection
} from './Sections'
import { MessageType } from './Sections/Forms/FormMessage';
import NotificationsRequest from 'xAppLib/UIelems/NotificationsRequest';
import Experiment from 'xAppLib/AB/Experiment';
import Breadcrumb from 'xAppLib/MenuNav/Breadcrumb'
import Section from './Section';

import InstCons_cosm_nurse from 'views/instcons/InstCons_cosm_nurse';


const Debugger = ({visible = false, store, valids, script_type,req_type,med_data,page_state,validsErrorsMsg}) => {
	// console.log("store",store,valids)
	const { full_address, shipping_address_components, sign, ...tmp} = store
	tmp.full_address = !!full_address && "[Replaced Oject]"
	tmp.shipping_address_components = !!shipping_address_components && "[Replaced Oject]"
	tmp.sign = !!sign && "[Replaced Oject]"
	if (!visible)
		return null
	return <div style={{position:'sticky', top:'60px',zIndex:1,background:'white'}}>
				<pre style={{background:'pink',padding:'2px 4px'}}>{script_type} / {req_type} / {(med_data?.name || '??')} / {page_state} / {validsErrorsMsg.length}</pre>
				<div style={{maxHeight:'200px',overflow:'scroll',padding:2,display:'flex',width:'100%'}}>

					<pre style={{fontSize:8,lineHeight:'1em',width:'50%'}}>
						{JSON.stringify(tmp,null,2)}
					</pre>
					<pre style={{fontSize:8,lineHeight:'1em',width:'50%',padding:2,borderLeft:'1px solid #ccc'}}>
						{JSON.stringify(valids,null,2)}

					</pre>
				</div>
			</div>;
}


const FormVariant = ({children}) => {
	return children?.();
}
 
import { cls } from 'views/NUI/utils';
import BreadCrumbs from './Sections/BreadCrumbs';
import LoginBanner from '../../xAppLib/UIelems/LoginBanner';
import { Card } from "../UIelems/Card";
import NeedPathology from "./Sections/Forms/NeedPathology";
import ConfirmVideoSelfTest from "./Sections/Forms/ConfirmVideoSelfTest";
import ExprCons from "./Sections/Forms/ExprCons";
import Consent from './Sections/Forms/Consent';
import PriceUpdate from 'xAppLib/AHPRA/PriceUpdate';
import MedSearch from './MedSearch';
import ConditionsAndServicesTiles from './ConditionsAndServicesTiles';
import ConsultMessage from '../NUI/ConsultMessage';
import { RestrictedMedsPrompt } from './Sections/HDR';
import FeatureTiles from '../../xAppLib/UIelems/FeatureTiles';
import HeidiTranscription from './Sections/Forms/HeidiTranscription';

const Steps = ({children}) => {
	return <div className="md:border-t  md:border-gray-200 mt-8 md:-mx-4">
			<nav className="" aria-label="Progress">
			<ol
				role="list"
				className="rounded-md overflow-hidden md:flex md:border-gray-200 md:rounded-none"
			>
			{children}
			
			</ol>
			</nav>
		</div>;
}

const Step = ({href, id, last, name, description, current, onClick}) => {
	
	return <li  className="relative overflow-hidden md:flex-1" onClick={onClick}>
				<div
				className={cls(
					id === 1 ? 'border-b-0 rounded-t-md' : '',
					last ? 'border-t-0 rounded-b-md' : '',
					'border overflow-hidden md:border-0',
					current?"text-is-blue border-is-blue":"text-gray-500 border-gray-200",
					onClick&&'cursor-pointer'
				)}
				>
					<a href={href} aria-current="step">
                    <span
                      className={cls("absolute top-0 left-0 w-1 h-full  md:w-full md:h-1 md:bottom-0 md:top-auto",current?"bg-is-blue":'bg-gray-200')}
                      aria-hidden="true"
                    />
                    <span
                      className={cls(
                        id !== 1 ? 'md:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className={cls("w-10 h-10 flex  items-center justify-center border-2  rounded-full", current ? "border-is-blue font-semibold" : "border-gray-300")}>
                          <span>{id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span className={cls("text-sm font-semibold  tracking-wide uppercase")}>
                          {name}
                        </span>
                        <span className="text-sm font-medium text-gray-500">{description}</span>
                      </span>
                    </span>
                  </a>
				</div>{id != 1 ? (
                  <>
                    {/* Separator */}
                    <div className="hidden absolute top-0 left-0 w-3 inset-0 md:block" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
			</li>
				
	
	

	
}
 
 

const Med = (props) => <MedData  {...props}>
	{
		(med_params) => {
			const {med_data,loading,med_error} = med_params

			return <Container 
						className="meds"
						fluid
					>
					<Helmet>
						<title>{med_data && (med_data.name || med_data.n) || '...'}</title>
					</Helmet>
					{loading && <Container><div className='my-12 flex text-md text-gray-500 font-semibold items-center'>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							className="animate-spin mr-3 h-6 w-6 text-current"
							viewBox="0 0 24 24"
							>
							<circle
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
								className="opacity-25"
							></circle>
							<path
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								className="opacity-75"
							></path>
					</svg>Loading...
					</div></Container>}
					{med_error && <Container>
									<br/><br/>
									<Message
										visible
										error
										size = 'huge'
										header = "There was a problem procressing your request. Please try our navigation menu."
									 />
						</Container>}
					{med_data && ((!med_data.name && med_data.n) ? 
						<>
							<div className="form-intro bg-white">
								<div className={cls("border-y border-is-gray-50 p-4 md:px-20 md:py-8")}>
										<Breadcrumb
											className='catalog'
											curr_name='Online Prescription'
											root_name='Home'
											nav_base='/'
										/>
									</div>
									
									<MedSearch />
							</div>
						</>
					 : <MedForm {...med_params} >
					{
							(form_params) => {
								const {script_type, req_type, sending, onSubmit, page_state} = form_params
								const { enable_patho,enable_profile,enable_shipping,enable_pts_dets,
										enable_doc_cons,enable_doc_presc,enable_qs,enable_extra_info,enable_delivery,enable_medselection,enable_dva_section,
										enable_exprcons,enable_cado,enable_summary,enable_paym,enable_cosm_treat,enable_sign,
										enable_yogp,enable_alt_pharm,enable_skype,enable_pharm, enable_noti, enable_doc_warn, enable_fulfilment,
										enable_standalone_evermed_delivery, valids, showErrors,
										show_hdr, show_form, show_sending, show_complete, show_form_err, enable_disclaimer, step, setStep, show_continue, enable_medicare_warn,
										is_user_prof_required, acc_must_verif, med_unsuit,
										enable_cosmdoc, show_YOGP, enable_treatment_plan, enable_need_patho, enable_confirm_video, enable_qualcons,
										show_MyHR,enable_myhr
									} = form_params;

								return <>
									<Debugger {...med_params} {...form_params}/>


									<div className="form-intro bg-white py-4 md:py-8 border-y border-solid border-is-gray-50">
									<Container>
										<div>
										<BreadCrumbs {...med_params} {...form_params} short={true}/>
										<Intro {...med_params} {...form_params} />
										<MedDets {...med_params} {...form_params} />
										</div>
										</Container>
									</div>

									<section className={cls('form-area px-4 md:px-0 md:w-[700px] mx-auto mt-4 md:mt-8', app.user.user_in ? 'lg:w-[800px]' : 'lg:w-[850px]')}>
										<Form size={!app.acl.app_use && 'big' || undefined}>
											<ValidationErrors {...med_params} {...form_params}  />

											{ show_form_err && show_form &&
												<Messages {...med_params} {...form_params} />
											}

											<Experiment id='-PbrrwFBQraFN40QgRRS0w'>
												<FormVariant variant={0} defaultVariant>
													{()=>{
                                                        const [discount, setDiscount] = useState(null);

														const step1 = step == 'start' || step == 'confirm'
														const step2 = step == 'confirm'
														return <>
															<SkypeButtons visible={show_form && step1} enabled={enable_skype} {...med_params} {...form_params}  />
															<CosmDoc visible={show_form && step1} enabled={enable_cosmdoc} {...med_params} {...form_params}  />
															<HDR visible={show_hdr && step1} {...med_params} {...form_params} />

															{(!app.user.user_in && !Med.access(props, true, true)) ?
															<>
																<FeatureTiles steps margin="my-8"/>
																<LoginBanner clsn="mb-0 mt-4"/>
																<FeatureTiles margin="mt-[122px]"/>
															</>
															:
															<>
															<ProfileSection visible={show_form && step1} enabled={enable_profile} {...med_params} {...form_params} />
															<TermPtsDets visible={show_form && step1} enabled={enable_pts_dets} {...med_params} {...form_params} /> 
															<FormMessage visible={show_form && step1} enabled={acc_must_verif} type={MessageType.MUST_VERIF} {...med_params} {...form_params} /> 
															<FormMessage visible={show_form && step1} enabled={is_user_prof_required} type={MessageType.INCOMPLETE_PROFILE} {...med_params} {...form_params} /> 
															<MedUnsuit visible={show_form && step1} enabled={med_unsuit} {...med_params} {...form_params} /> 
															<NeedPathology visible={show_form && step1} enabled={enable_need_patho} {...med_params} {...form_params} />
															<ConfirmVideoSelfTest visible={show_form && step1} enabled={enable_confirm_video} {...med_params} {...form_params} />
															{/*need_ihi: {need_ihi?.toString()}, has_ihi: {has_ihi?.toString()}, prof_compl: {prof_compl?.toString()}, prof_miss: {prof_miss?.toString()}, acc_must_verif: {acc_must_verif?.toString()}*/}
															<DocCons visible={show_form && step1} enabled={enable_doc_cons} {...med_params} {...form_params} />
															<DocScr visible={show_form && step1} enabled={enable_doc_presc} {...med_params} {...form_params} />
															{!app.settings.iprep && <PathologyItems visible={show_form && step1} enabled={enable_patho} {...med_params} {...form_params} />}
															<MedicalConsultation key={med_params.med_code} visible={show_form && step1} enabled={enable_qs}  {...med_params} {...form_params} />
															{app.settings.iprep && <PathologyItems visible={show_form && step1} enabled={enable_patho} {...med_params} {...form_params} />}
												 			<ExtraInfo visible={show_form && step1} enabled={enable_extra_info} {...med_params} {...form_params} /> 
															{app.settings.iprep && <ShippingAddressNew visible={show_form && step1} enabled={enable_shipping} {...med_params} {...form_params} />}
															<MedSelection visible={show_form && step1} enabled={enable_medselection} {...med_params} {...form_params} />
															<DVASection visible={show_form && step1} enabled={enable_dva_section} {...med_params} {...form_params} />
															<CollectionMethod visible={show_form && step1} enabled={enable_delivery} {...med_params} {...form_params} />
															<EvermedDeliverySection visible={show_form && step1} enabled={enable_standalone_evermed_delivery} {...med_params} {...form_params} />
															<Fulfilment visible={show_form && step1} enabled={enable_fulfilment} {...med_params} {...form_params} discount={discount} />
															<PharmacySelect visible={show_form && step1} enabled={enable_pharm} {...med_params} {...form_params} />
															<AltPharm visible={show_form && step1} enabled={enable_alt_pharm} {...med_params} {...form_params} />
															{!app.settings.iprep && <ShippingAddressNew visible={show_form && step1} enabled={enable_shipping} {...med_params} {...form_params} />}
															<ExprCons visible={show_form && step1} enabled={enable_exprcons} {...med_params} {...form_params} />
															<Cado visible={show_form && step1} enabled={enable_cado} {...med_params} {...form_params} />
															<PriceUpdate enable={show_form && step1 && enable_qualcons} {...med_params} {...form_params} />
															<DocMedicareWarn visible={show_form && step1} enabled={enable_medicare_warn}  {...med_params} {...form_params}  />
															<Summary visible={show_form && step1} enabled={enable_summary}  {...med_params} {...form_params} setDiscount={setDiscount} />
															<TreatmentPlan visible={show_form && step1} enabled={enable_treatment_plan}  {...med_params} {...form_params} />
															<Payment visible={show_form && step1} enabled={enable_paym} {...med_params} {...form_params} />
															<CosmTreat visible={show_form && step1} enabled={enable_cosm_treat} {...med_params} {...form_params} />
															<Consent visible={show_form && step1} enabled={enable_cosm_treat} {...med_params} {...form_params} />
															<YOGP visible={show_YOGP && show_form && step1} enabled={enable_yogp} {...med_params} {...form_params} />
															<MyHR visible={show_MyHR && show_form && step1} enabled={enable_myhr} {...med_params} {...form_params} />
															<HeidiTranscription visible={show_form && step1} enabled={enable_doc_cons} {...med_params} {...form_params} />
															<br/>
															<Section.Content enabled={show_continue} className={page_state != 'form' ? 'border-0' : null}>
																<Signature visible={show_form && step1} enabled={enable_sign} {...med_params} {...form_params} />
																<NotificationsRequest
																	enabled={enable_noti && show_form && step1}
																	description={`You can choose to receive notifications on this ${ app.settings.is_ionic ? 'app' : 'browser'} to be notified when ${script_type=='doccons' ? 'our doctor calls you' : 'your script is approved'}.`}
																	show_if_needed
																/>

															<DocCallWarning  visible={show_form && step1} enabled={enable_doc_warn} {...med_params} {...form_params} />
															
															<ValidationErrors  visible={show_form && step1} {...med_params} {...form_params}  />
															{showErrors && valids?.dcs_med_desc === false && <RestrictedMedsPrompt />}

															<Messages  {...med_params} {...form_params} />
															
															{/* {page_state=='form_sent_ok' && form_params?.form_send_res?.type=='instcons_await' && 
																<InstCons_cosm_nurse 
																	sid={form_params?.form_send_res?.sid} 
																	med_params={med_params} 
																	form_params={form_params}
																	send_res={form_params?.form_send_res}
																/>
															} */}

															{show_form && show_continue && <Button
																	loading={sending}
																	disabled={!(
																		!sending && (
																			page_state=='form'
																			|| (page_state=='form_err' && enable_exprcons)
																		)
																	)}
																	// size="big"
																	onClick={ _=> onSubmit('confirm','start') }
																	fluid
																	color="blue"
																	style={{width: '100%', marginTop: '1rem'}}
																	data-testid='button-continue'
																>
																Continue
															</Button> }
														
															<Disclaimer  visible={show_form && step1} enabled={enable_disclaimer} />
																

															<Confirmation  visible={show_form && step2} {...med_params} {...form_params}
																onClose={()=>setStep('start')}
																onConfirm={()=>onSubmit('submit','start')}
															/></Section.Content>
															</>}
														</>
													}}
												</FormVariant>
												<FormVariant variant={1} >
												{()=>{
														const step1 = step == 'start'
														const step2 = step == 'options'
														const step3 = step == 'confirm'
														const next = step1 && "options" || step2 && "confirm" || "submit"
														const previous = step1 && "start" || step2 && "start" || "options"
														return <>

														<HDR visible={show_form && step1} {...med_params} {...form_params} />

														{step != 'submit' &&<Steps>
															<Step id='1' name="Request Details" current={step1}
																// description="Complete your details and medical questionnaire"
																onClick={()=>{
																	setStep('start')
																}}
															/>
															<Step id='2' name={form_params.is_cosm?"Patient Agreement":"Delivery and Options"}  current={step2}
																description={form_params.is_cosm?"Signature":"Choose how you would like to receive it"}
																onClick={()=>{
																	if (!step1)
																		setStep('options')
																}}
															/>
															<Step id='3' name="Confirm"  current={step3}
																description="Final check and submit"
															/>
														</Steps>}
														{(step1 || step2 ||step3 ) && <div className=" md:px-4 md:pb-4 md:-mx-4">
															<SkypeButtons visible={show_form && step1} enabled={enable_skype} {...med_params} {...form_params}  />
															<CosmDoc visible={show_form && step1} enabled={enable_cosmdoc} {...med_params} {...form_params}  />
															<ProfileSection visible={show_form && step1} enabled={enable_profile} {...med_params} {...form_params} />
															<TermPtsDets visible={show_form && step1} enabled={enable_pts_dets} {...med_params} {...form_params} /> 
															<DocCons visible={show_form && step1} enabled={enable_doc_cons} {...med_params} {...form_params} />
															<DocScr visible={show_form && step1} enabled={enable_doc_presc} {...med_params} {...form_params} />
															{!app.settings.iprep && <PathologyItems visible={show_form && step1} enabled={enable_patho} {...med_params} {...form_params} />}
															<MedicalConsultation key={med_params.med_code} visible={show_form && step1} enabled={enable_qs}  {...med_params} {...form_params} />
															{app.settings.iprep && <PathologyItems visible={show_form && step1} enabled={enable_patho} {...med_params} {...form_params} />}
															<ExtraInfo visible={show_form && step1} enabled={enable_extra_info} {...med_params} {...form_params} /> 
															
															<Cado visible={show_form && step1} enabled={enable_cado} {...med_params} {...form_params} />
															<PriceUpdate enable={show_form && step1 && enable_qualcons} {...med_params} {...form_params} />


															
															
															{app.settings.iprep && <ShippingAddressNew visible={show_form && step2} enabled={enable_shipping} {...med_params} {...form_params} />}
															<CollectionMethod visible={show_form && step2} enabled={enable_delivery} {...med_params} {...form_params} />
															<PharmacySelect visible={show_form && step2} enabled={enable_pharm} {...med_params} {...form_params} />
															{!app.settings.iprep && <ShippingAddressNew visible={show_form && step2} enabled={enable_shipping} {...med_params} {...form_params} />}

															<DocMedicareWarn visible={show_form && step2} enabled={enable_medicare_warn}  {...med_params} {...form_params}  />
															<CosmTreat visible={show_form && step1} enabled={enable_cosm_treat} {...med_params} {...form_params} />
															<AltPharm visible={show_form && step2} enabled={enable_alt_pharm} {...med_params} {...form_params} />
															<DocCallWarning  visible={show_form && step2} enabled={enable_doc_warn} {...med_params} {...form_params} />

															<Summary visible={show_form && step2} enabled={enable_summary}  {...med_params} {...form_params} />
															<Payment visible={show_form && step2} enabled={enable_paym} {...med_params} {...form_params} />
															<Signature visible={show_form && step2} enabled={enable_sign} {...med_params} {...form_params} />

															<YOGP visible={show_YOGP && show_form && step2} enabled={enable_yogp} {...med_params} {...form_params} />

															{show_form && step3 && <Section.Header>Confirm Your Submission</Section.Header>}
															<Confirmation inline visible={show_form && step3} {...med_params} {...form_params}/>
															<NotificationsRequest
																basic
																enabled={enable_noti && show_form && step3}
																description={`You can choose to receive notifications on this ${ app.settings.is_ionic ? 'app' : 'browser'} to be notified when ${script_type=='doccons' ? 'our doctor calls you' : 'your script is approved'}.`}
																show_if_needed
															/>

															<ValidationErrors  visible={show_form} {...med_params} {...form_params}  />

															<Messages  {...med_params} {...form_params} />
															<Disclaimer  visible={show_form && step3} enabled={enable_disclaimer} />
															
															{show_form && show_continue &&<div className='flex mt-4 mb-4'>
																{!step1 && <Button
																	loading={sending}
																	disabled={sending || (page_state!='form' && page_state!='form_err' )}
																	size="big"
																	onClick={ _=> setStep(previous) }
																	fluid
																	style={{flex:1}}
																>
																	<Icon name='angle left' />
																	Back
																</Button>}
																<Button
																	loading={sending}
																	disabled={sending || (page_state!='form' )}
																	size="big"
																	onClick={ _=> onSubmit(next,previous) }
																	fluid
																	color="green"
																	style={{flex:3}}
																>
																{step3 ? "Submit" : "Continue"} <Icon name='angle right' />
															</Button>
															</div> }
														
															</div> 
														||
															<Messages  {...med_params} {...form_params} />
														}

														</>
													}}
												</FormVariant>

											</Experiment>

											

											<ClosePopup {...med_params} {...form_params} />

											{ show_sending && 
												<ConsultMessage
													type="basic"
													header="Submitting your request now. Please wait."
													content={<><span className="text-center block">We're sending your request to our doctors for review!</span><br />
														<img className="mx-auto" src="https://storage.googleapis.com/instant-med-public/loading_logo.gif" width="100" /></>
													}
												/>
											}

											{ show_complete && <>
												<NotificationsRequest
													enabled={enable_noti}
													description={`You can choose to receive notifications on this ${ app.settings.is_ionic ? 'app' : 'browser'} to be notified when ${script_type=='doccons' ? 'our doctor calls you' : 'your script is approved'}.`}
													show_if_needed
												/>
												<DocCallWarning enabled={enable_doc_warn} {...med_params} {...form_params} />
											</>}
										</Form>
									</section>
									{app.dev_env && <div className='ui segment basic'>script_type: {script_type}, req_type: {req_type}, page_state: {page_state}, step: {step}</div>}
									</>
							}}
					</MedForm>)}
				</Container>
		}
	}
</MedData>

export default Med

Med.access = (props, no_redir=false, for_req=false) => {
	const cat_nm = props.alt_cat?.replace(/^_/, '') || props.match.params.cat_nm || 'pharm';
	return 	( props.public
					|| app.runtime.med_allow_nouser
					|| (!app.user.user_in && !for_req)
					|| (app.runtime.medcert_allow_nouser||org_model.org_conf_itm(app.runtime.org, 'medcert_allow_nouser')) && props.match.params.cat_route=='certs'
					|| ( no_redir ? user_model.check_access('pg_med', cat_nm) : user_model.limit_access('pg_med', cat_nm, true, app.history.location.pathname) )
				)
			&& ( props.med_allow_excl
					|| app.runtime.med_allow_excl
					|| ( no_redir ? user_model.check_access('show_medcat', {k: props.match.params.med_code || props.med_code, cat_nm, alt_cat:props.alt_cat}) : user_model.limit_access('show_medcat', {k: props.match.params.med_code || props.med_code, cat_nm, alt_cat:props.alt_cat}) )
				)
}